import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import MyRACHero from "../assets/projects/myrac-thumb.jpg";
import MyRAC1 from "../assets/projects/myrac1.jpg";
import MyRAC2 from "../assets/projects/myrac2.jpg";
import MyRAC3 from "../assets/projects/myrac3.jpg";
import MyRAC4 from "../assets/projects/myrac4.jpg";
import MyRAC5 from "../assets/projects/myrac5.jpg";
import SectionContent from "../layout/sectionContent";

function MyRAC() {
  return (
    <div>
      <ProjectHero
        client="RAC"
        heroImg={MyRACHero}
        heading="Optimising the MyRAC mobile app"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2023
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Mobile app
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Design Director
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            The RAC is one of the UK’s most trusted roadside assistance brands.
            After a major investment in 2021, Futurice was brought in to
            transform the RAC's digital capabilities and customer experience
            across their suite of digital products, including their flagship
            MyRAC mobile app.
          </h5>
          <p>
            The RAC had the ambition to convert the MyRAC app from a service
            that users engaged with only a few times a year, to a valuable tool
            that supports driver’s day-to-day driving activities. To support
            this, our aim was to re-launch and redesign this app, with a view to
            removing pain points, adding more features and enhancing customer
            engagement.
          </p>
        </SectionContentBody>
        <SectionContentImg src={MyRAC1} />
        <SectionContentBody>
          <h5>What we did</h5>
          <p>
            At the time, RAC members were logging into the app on average twice
            a year; so we wanted to understand how we could make it far more
            compelling and user-friendly. To start with, Futurice created a new
            information architecture that connected various functionalities
            within the app in a more intuitive way, using over-arching
            categories that were more attuned to how users thought about vehicle
            ownership. A key part of this was repositioning the Home view which
            was largely focused on sharing RAC company news, into more of a
            personalised dashboard providing routes into helpful information and
            features to support daily driving and vehicle maintenance.
          </p>
          <p>
            We also looked at new features that would help to reposition the
            MyRAC app as a valuable daily driving tool, starting with the Fuel
            Finder feature, which would enable users to compare fuel prices in
            their local area and find the best value option for refuelling their
            vehicle.
          </p>
        </SectionContentBody>
        <SectionContentImg src={MyRAC2} />
        <SectionContentBody>
          <h5>How we defined the solution</h5>
          <p>
            We created a set of low-fidelity user flows outlining the new
            information architecture and features, which we brought into
            moderated testing sessions with users, to capture feedback on our
            early hypotheses. The insights gained through these testing sessions
            enabled us to iterate the updated app design concepts and into a
            high-fidelity prototypes and validate the intuitiveness of the
            proposed information architecture updates for the service. These
            high-fidelity design were then taken into a second round of
            moderating user testing and validation, before the build work began.
          </p>
        </SectionContentBody>
        <SectionContentImg src={MyRAC3} />
        <SectionContentBody>
          <h5>Designing for scalability</h5>
          <p>
            To ensure that this new MyRAC app design could be continuously
            optimised and enhanced after launch, by the RAC’s Product Squads, we
            integrated our design work into the Global RAC Design system that
            the Futurice team were building for the RAC. To do this we built an
            app specific component library to support all app projects, aligned
            to the structures and governance established in the core web
            component library, and connected to the Global RAC style guide that
            cascades through all components within the RAC design systems.
          </p>
          <p>
            This ensured a consistent brand experience for customers across
            digital touch-points, and a consistent experience for
            cross-functional Product Squads when working on digital products
            across formats.
          </p>
        </SectionContentBody>
        <SectionContentImg src={MyRAC4} />
        <SectionContentBody>
          <h5>The impact of our work</h5>
          <p>
            The new-look MyRAC app went live in summer 2023, and comes with a
            host of helpful extras, including the ability to set reminders
            around everything from policy renewals to checking your car tyre
            pressure. We’ve also introduced a flagship feature called Fuel
            Finder, where you can find the cheapest fuel in your area by
            entering your location postcode into a map. This feature is a really
            smart, useful life tool, delivering added value amid a
            cost-of-living crisis.
          </p>
          <p>
            Over the course of this period, <b>monthly active users</b>, one of
            RAC’s key KPIs for the product, <b>increased by 19%</b>.
          </p>
        </SectionContentBody>
        <SectionContentImg src={MyRAC5} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default MyRAC;
