import styled from "styled-components";
import device from "../global/mediaQueries";


const MenuDivider = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
  margin: 0 ${props => props.theme.space.s7};

  @media ${device.laptop} {
   display:none;
  }
`;

export default MenuDivider;