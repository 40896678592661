import styled from "styled-components";
import device from "../global/mediaQueries";

const SectionContentBody = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 792px;
  overflow: hidden;

  h1,
  h2,
  h5 {
    margin: ${(props) => props.theme.space.s4} 0
      ${(props) => props.theme.space.s7} 0;

    @media ${device.laptop} {
      margin: ${(props) => props.theme.space.s4} 0
        ${(props) => props.theme.space.s7} 0;
    }
  }
  h1 {
    margin-bottom: ${(props) => props.theme.space.s9};

    @media ${device.laptop} {
      margin-bottom: ${(props) => props.theme.space.s6};
    }
  }
  b {
    color: ${(props) => props.theme.colors.black};
  }
  h3 span, p span {
    color: ${(props) => props.theme.colors.keyline};
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: ${(props) => props.theme.space.s9} 0;
    background: ${(props) => props.theme.colors.keyline};
  }
`;
export default SectionContentBody;
