import React from "react";
import styled from "styled-components";
import ActionBtn from "../nav/actionBtn";
import { Link } from "react-router-dom";
import device from "../global/mediaQueries";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";
import Spacer from "../layout/spacer";

const LeadCardWrap = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  text-decoration: none;
  margin: ${(props) => props.theme.space.s8} 0
    ${(props) => props.theme.space.s4} 0;

  &:visited,
  h4 {
    color: ${(props) => props.theme.colors.black};
  }

  .thumbImg {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 660px;

    img {
      object-fit: cover;
      height: auto;
      max-height: 100%;
      width: auto;
      min-width: 100%;
      transition: transform 3s;

      &:hover {
        transform: scale(1.05);
      }
    }

    @media ${device.mobileL} {
      height: 220px;
    }
  }
  h4 {
    margin-bottom: ${(props) => props.theme.space.s6};
  }

  @media ${device.laptop} {
  }
  @media ${device.mobileL} {
    margin: ${(props) => props.theme.space.s8} 0
      ${(props) => props.theme.space.s2} 0;
  }
`;

const LeadContentWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: ${(props) => props.theme.space.s8} ${(props) => props.theme.space.s8}
    0 ${(props) => props.theme.space.s8};
  max-width: 740px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.white};

  @media ${device.laptop} {
    max-width: 80vw;
  }

  @media ${device.mobileL} {
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: ${(props) => props.theme.space.s5} 0;
  }
`;

const LeadCard = ({ image, client, desc, type, link }) => {
  return (
    <div>
      <LeadCardWrap to={link}>
        <div class="thumbImg">
          <img src={image} alt={client} />
        </div>
        <LeadContentWrap>
          <Spacer mb={10}>
            <small>
              <span>
                <b>Client /</b>
              </span>{" "}
              {client}
            </small>
          </Spacer>
          <Spacer mb={20}>
            <h3>
              <b>{desc}</b>
            </h3>
          </Spacer>
          <TagWrap>
            <Tag>
              <small>UI</small>
            </Tag>
            <Tag>
              <small>UX</small>
            </Tag>
            <Tag>
              <small>Design System</small>
            </Tag>
          </TagWrap>

          <ActionBtn page={link} />
        </LeadContentWrap>
      </LeadCardWrap>
    </div>
  );
};

export default LeadCard;
