import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import SectionContent from "../layout/sectionContent";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import Vau1 from "../assets/projects/vau1.jpg";
import Vau2 from "../assets/projects/vau2.jpg";
import Vau3 from "../assets/projects/vau3.jpg";
import Vau4 from "../assets/projects/vau4.jpg";
import Vau5 from "../assets/projects/vau-thumb.jpg";

function Vaughan() {
  return (
    <div>
      <ProjectHero
        client="Vaughan Designs"
        heroImg={Vau5}
        heading="Luxury interior design eCommerce platform"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small><b>Year:</b> 2018</small>
            </Tag>
            <Tag>
              <small><b>Type:</b> eCommerce website</small>
            </Tag>
            <Tag>
              <small><b>Role:</b> Project Lead,
            Design Lead</small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            Vaughan design and manufacture high-end interior fittings and
            furnishings for the luxury market.
          </h5>
          <p>
            As part of a broader brand refresh, Approached us to help redesigned
            their 10 year old website, and create a contemporary eCommerce
            experience for their customers.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Vau1} />
        <SectionContentBody>
          <h5>Getting started</h5>
          <p>
            We facilitated a kickoff workshop to align the project team, map key
            stakeholders, define the key business objectives and identify
            customer segments. Vaughan’s core customer groups are professional
            interior designers, and interior design practices, so it was
            important for the site to meet the needs of these business user
            groups.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Vau2} />
        <SectionContentBody>
          <h5>Our approach</h5>
          <p>
            A key feature identified for interior design practices was an
            advanced Wishlist area of the site, where potential purchases could
            be grouped by room type for individual interior design projects. We
            worked closely with the Vaughan team to co-create this feature,
            leveraging their in-depth understanding of interior design project
            management to build a robust and simple solution.
          </p>
          <p>
            We completely redesigned the end-to-end eCommerce user journey, to
            better represent the the expert craftsmanship of Vaughan’s high-end
            products, and create an online e experience that captured the
            experience of visiting one of Vaughan’s show rooms.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Vau3} />
        <SectionContentBody></SectionContentBody>
        <SectionContentBody>
          <h5>A scalable design approach</h5>
          <p>
            A comprehensive Ui kit was created to enable smooth handover to
            development, and improve the efficiency of scaling the platform in
            the future. We followed the principles of Atomic Design as part of
            an ongoing initiative to create robust design solutions for our
            clients.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Vau4} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default Vaughan;
