import styled from "styled-components";
import device from "../global/mediaQueries";
import LeadLabel from "../layout/leadLabel";
import SectionContentBody from "../layout/sectionContentBody";

const ProjectHeroWrap = styled.div`
  position: relative;
  width: 100%;
  height: 720px;
  overflow: hidden;
  background-image: url(${(props) => props.img});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @media ${device.mobileL} {
    background-attachment: initial;
  }
`;
const ProjectHeroTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: 996px;
  width: 100%;
  padding: ${(props) => props.theme.space.s9} ${(props) => props.theme.space.s9}
    0 ${(props) => props.theme.space.s9};
  background: ${(props) => props.theme.colors.white};

  h1 {
    padding: ${(props) => props.theme.space.s5} 0 0 0;
    margin: 0;
  }

  @media ${device.laptop} {
    padding: ${(props) => props.theme.space.s7}
      ${(props) => props.theme.space.s5} 0 ${(props) => props.theme.space.s5};
  }
`;

const ProjectHero = ({ client, heroImg, heading }) => {
  return (
    <ProjectHeroWrap img={heroImg}>
      <ProjectHeroTitle>
        <SectionContentBody>
          <LeadLabel label={client} />
          <h1>{heading}</h1>
        </SectionContentBody>
      </ProjectHeroTitle>
    </ProjectHeroWrap>
  );
};

export default ProjectHero;
