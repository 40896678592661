import styled from "styled-components";
import device from "../global/mediaQueries";

const ExperienceWrap = styled.div`
  display: flex;
  padding: ${(props) => props.theme.space.s6} 0;
  border-top: 1px solid ${(props) => props.theme.colors.keyline};

  p {
    margin-bottom: 0;
  }
  b {
    color: ${(props) => props.theme.colors.black};
  }

  span {
    color: ${(props) => props.theme.colors.keyline};
  }
  br {
    display: none;
    
    @media ${device.mobileL} {
      display: block;
    }
  }
`;
export default ExperienceWrap;
