import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import SectionContent from "../layout/sectionContent";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import DevyThumb from "../assets/projects/devy-thumb.jpg";
import Devy1 from "../assets/projects/devy1.jpg";
import Devy2 from "../assets/projects/devy2.jpg";
import Devy3 from "../assets/projects/devy3.jpg";
import Devy5 from "../assets/projects/devy5.jpg";
import Devy7 from "../assets/projects/devy7.jpg";
import Devy8 from "../assets/projects/devy8.jpg";

function Devyce() {
  return (
    <div>
      <ProjectHero
        client="Stour Marine"
        heroImg={DevyThumb}
        heading="Virtual work phone app for UK telecoms operator"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2020
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> mobile app
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Project Lead, Design Lead
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            The Devyce app uses eSIM technology to create a virtual work phone
            on your personal handset. Users are able to make and receive phone
            calls, send and receive messages, and keep a separate Devyce address
            book, using a separate work phone number, without the need to have
            two physical handsets.
          </h5>
          <p>
            The service is aimed at sales teams making a high volume of daily
            B2C calls, with a view to significantly reducing the operational
            costs of leasing hardware, and offering tracking and phone number
            allocation controls to team leaders and managers.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Devy1} />
        <SectionContentBody>
          <h5>Kickoff</h5>
          <p>
            The Device team came to us to help them design, build and launch the
            MVP app. My role on this project was to oversee all design and
            research work, and to act as a project manager and owner of the
            client relationship. We kicked off the project with a half day
            workshop with the full project team. During the workshop we used the
            Lean Service Creation (LSC) toolkit to help define business
            objectives and what success would look like, to identify risks and
            our target customer groups.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Devy8} />
        <SectionContentBody>
          <h5>Discovery</h5>
          <p>
            The project then moved into a discovery phase where we explored the
            prpblem space, before converging on a solution and creating initial
            wireframes, which were refined and validated through user testings
            sessions. This then enasbled the team to create a priotised backlog
            of user stories for the MVP app.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Devy7} />
        <SectionContentBody>
          <h5>Crafting the look and feel</h5>
          <p>
            With validated wireframes completed, the next phase focused on UI
            and Visual Design, kicking off with a workshop to ideate and
            co-create a visual identity for the Device app. Key initial views
            were agreed with the tech team, which were then prioritised for
            visual design work.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Devy2} />
        <SectionContentImg src={Devy3} />
        <SectionContentBody>
          <h5>Our approach</h5>
          <p>
            We worked closely with the Device PO and Teach Lead to co-create the
            app flows and UX, using a scrum board to track design and tech
            backlogs. Native versions of the app were developed for Android and
            iOS platforms, and Futurice created a comprehensive (Atomic Design)
            pattern library, following best practices for each platform, to
            facilitate easy handover to the the tech teams. All project
            materials from budget burn-down, through to research and workshop
            materials, were hosted on a Miro project board make them as
            accessible as possible for all team members.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Devy5} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default Devyce;
