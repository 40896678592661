import React from "react";
import styled from "styled-components";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import SectionContent from "../layout/sectionContent";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import JPPHero from "../assets/projects/jpp-thumb.jpg";
import JPP1 from "../assets/projects/jpp1.jpg";
import JPP2 from "../assets/projects/jpp2.jpg";
import JPP3 from "../assets/projects/jpp3.jpg";
import JPP4 from "../assets/projects/jpp4.jpg";
import JPP5 from "../assets/projects/jpp5.jpg";

const FigmaEmbed = styled.iframe`
  width="1200"
  height="550"
  border: 1px solid ${(props) => props.theme.colors.keyline};
`;

function JPP() {
  return (
    <div>
      <ProjectHero
        client="Jagex"
        heroImg={JPPHero}
        heading="Imagining the future of game launchers"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2023
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Web app
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Design Director
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            I've been working with the team at Jagex since 2021, when Futurice
            won a tender to help Jagex redesign their RuneScape and Old School
            RuneScape web portals. That engagement helped to establish us as one
            of Jagex's most trusted partners, in part because of the great work
            we did together, but also because our teams simply got along really
            well.
          </h5>
          <p>
            Over the following years I've lead teams to support Jagex with a
            number of initiatives focused on migrating players to their new
            player account system, scaling and optimising the Jagex Design
            System (JDS), and designing Jagex's new game launcher and publishing
            platform (JPP).
          </p>
          <p>
            This work led to Jagex asking us to create a future vision for what
            the JPP Could become for both players and game developers, to help
            build excitement and advocacy within the exec level of the business,
            and secure support and investment for the next two years, to help
            bring this vision to market.
          </p>
        </SectionContentBody>
        <SectionContentImg src={JPP1} />
        <SectionContentBody>
          <h5>Understanding the problem</h5>
          <p>
            Jagex's brand mission is to create ‘forever games’ by empowering
            their community; what this means in practice is engaging with their
            rich and diverse player communities to ensure that the voice of the
            player informs the direction their games and services evolve in.
          </p>
          <p>
            Feedback from player communities told us that to really embrace
            another have launcher in the JPP, players wanted more than just
            another fancy library tool. Jagex’s players wanted a platform that
            empowered them to engage with their friends and the game communities
            that they're so passionate about, as well as to consume and share
            the content that these communities produce.
          </p>
          <p>
            Talking to third party game developers who might one day publish
            their games on the JPP platform, Jagex learned that the experience
            of publishing games on rival platforms that were currently on the
            market, was cumbersome and onerous. Game developers wanted a quick
            and straightforward way to create a marketing page for their games,
            be able to preview and fine-tune the end result, and be able to
            easily track and analyse the performance of their games.
          </p>
        </SectionContentBody>
        <SectionContentImg src={JPP2} />
        <SectionContentBody>
          <h5>Defining the player experience</h5>
          <p>
            Player insights shared by Jagex showed us that players were really
            keen to bring social elements into the JPP experience, as a way to
            stay connected to their friends and communities outside of the
            games. To realise this, we created a social panel that could be
            opened anywhere on the platform, offering players routes into the
            latest trending topics in their communities and letting them know
            which of their friends were currently online, alongside which games
            those friends were playing.
          </p>
          <p>
            Alongside this, we explored ways to surface user generated content
            across the platform giving users instant access to videos, polls and
            live streams of the games they love.
          </p>
        </SectionContentBody>
        <SectionContentImg src={JPP3} />
        <SectionContentBody>
          <h5>Defining the developer experience</h5>
          <p>
            To create a vision of the North Star for JPP's game developer
            experience, we focused on the process of publishing a new game on
            the platform. Building on insights gathered by Jagex's internal
            teams, alongside our in-depth benchmarking and desk research, we
            created a concept for a simple and intuitive process to upload the
            assets for a new game, abd create and review a marketing page for
            the game, before publishing it on the platform.
          </p>
          <p>
            Alongside this, we created concepts for how an analytics dashboard
            could look and function for game developers, giving them an
            immediate and clear view of how their newly launched games are
            performing.
          </p>
        </SectionContentBody>
        <SectionContentImg src={JPP4} />
        <SectionContentBody>
          <h5>The impact of our work</h5>
          <p>
            We purposely set out to design UI concepts that told a story,
            enabling us to develop the designs into highly polished prototypes,
            pushing Figma’s new boolean and variable features to create rich
            interactions and animations that helped to turn our Ui concepts into
            a believable interactive experience.
          </p>
          <p>
            These prototypes not only helped to build advocacy and support for
            the JPP's future, but also enabled Jagex's in-house design team to
            test and validate the concepts we created, with players, building
            confidence that this new direction for the platform would meet real
            player needs and expectations. The next steps for this work will be
            to further test and validate the UI concepts to help define a
            product vision and rid roadmap to lead the platform towards its
            North Star.
          </p>
        </SectionContentBody>
        <SectionContentImg src={JPP5} />
        <div>
          <FigmaEmbed>
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FZAqDpSyiElcNcGmlO64AgY%2FJPP-Concept%3Fpage-id%3D72%253A947%26node-id%3D72-949%26viewport%3D374%252C396%252C0.25%26t%3DZ1VBsuM1lR3IKGMM-1%26scaling%3Dscale-down%26starting-point-node-id%3D72%253A949%26show-proto-sidebar%3D1"
          </FigmaEmbed>
        </div>
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default JPP;
