import styled from "styled-components";
import device from "../global/mediaQueries";

const CtaGroup = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: ${(props) => props.theme.space.s4};
`;

export default CtaGroup;
