import styled from "styled-components";
import device from "../global/mediaQueries";

const MenuBtn = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: ${(props) => props.theme.space.s5};
  height: ${(props) => props.theme.space.s5};
  background: transparent;

  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
  @media ${device.laptop} {
    display:flex;
    align-items: flex-end;
  }

  div {
    position: relative;
    width: 24px;
    height: 2px;
    background: ${(props) => props.theme.colors.black};

    transition: all 0.3s linear;
    transform-origin: 1px;

    &:first-child {
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      width: ${(props) => (props.open ? "0" : "24px")};
    }
    &:nth-child(3) {
      transform: ${(props) => (props.open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export default MenuBtn;
