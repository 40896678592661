import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Bullets from "../layout/bullet";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import PLHero from "../assets/projects/plpb-thumb.jpg";
import PL1 from "../assets/projects/plpb1.jpg";
import PL2 from "../assets/projects/plpb2.jpg";
import SectionContent from "../layout/sectionContent";

function PLPB() {
  return (
    <div>
      <ProjectHero
        client="Futurice"
        heroImg={PLHero}
        heading="Pattern Library Playbook"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2023
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Thought Leadership
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Writer, Thinker, Designer
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            <a href="https://futurice.com/blog/pattern-library-playbook">
              Pattern Library Playbook
            </a>{" "}
            is a thought leadership piece that grew out of my efforts to record
            the best practices I’d developed for supporting clients in their
            ambitions to build a Design System for their products.
          </h5>
          <p>
            Over the last 8 years, I’ve lost count of the times I’ve had to help
            clients build the first version of Design System for their new
            product, and alongside this, working in consultancies has given me
            countless opportunities to observe how different organisations have
            approached their own Design Design system development and
            management.
          </p>
          <p>
            During this time I’ve seen the (mostly) good, the (sometimes) bad,
            and the (occasionally) downright awful of the Design Systems world,
            so I decided it was about time to give something back by pulling
            this experience together into a coherent and user friendly guide for
            others who are starting out on their journey to creating a new
            Design System.
          </p>
          <p>And the Thus, the Pattern Library Playbook was born.</p>
        </SectionContentBody>
        <SectionContentImg src={PL1} />
        <SectionContentBody>
          <h5>“Tell me what it is before I decide to read it...”</h5>
          <p>
            Writing a long article about an article I wrote seems kind of
            pointless, so here are the headlines for why you should read Pattern
            Library Playbook and how it could help you and your team to create a
            system that is usable and scalable.
          </p>
          <Bullets>
            <li>
              <p>
                Learn what a Pattern Library is, why it’s different from a
                Design System, and how your organisation could benefit from
                implementing one
              </p>
            </li>
            <li>
              <p>
                Understand the most common pitfalls of starting out with you
                first Pattern Library, and how to avoid them
              </p>
            </li>
            <li>
              <p>
                Follow a my framework for planning and structuring your library,
                using a free set of Miro canvases
              </p>
            </li>
          </Bullets>
          <p>
            You can read the full Pattern Library Playbook article, for free{" "}
            <span>
              <b>
                <a href="https://futurice.com/blog/pattern-library-playbook">
                  here
                </a>
              </b>
            </span>
          </p>
        </SectionContentBody>
        <SectionContentImg src={PL2} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default PLPB;
