import React, { useState } from "react";
import styled from "styled-components";
import device from "../global/mediaQueries";
import { Link } from "react-router-dom";
import MenuLink from "./menuLink";
import MenuBtn from "./menuBtn";
import MenuLinksList from "./menuLinksList";
import MenuIconsList from "./menuIconsList";
import MenuDivider from "../layout/menuDivider";
import { Links } from "../global/links";
import logo from "../assets/icons/twd.svg";
import dribbble from "../assets/icons/dribbbleBlack.svg";
import linked from "../assets/icons/linkedinBlack.svg";
import behance from "../assets/icons/behanceBlack.svg";
import insta from "../assets/icons/insta.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutButton } from "./logoutBtn";

const MenuWrap = styled.div`
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => props.theme.space.s7};
  background: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid ${(props) => props.theme.colors.divider};


  @media ${device.laptop} {
    height: auto;
    min-height: 70px;
    display: block;
    width: 100%;
    padding: 0 ${(props) => props.theme.space.s5};
  }
`;

const HeadWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
  align-items: center;

  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 70px;
    align-items: center;
  }
`;

const TwdLogo = styled(Link)`
  max-height: ${(props) => props.theme.space.s5};
`;

const MenuLinksWrap = styled.div`
  display: flex;
  align-items: left;
  transition: all 0.5s linear;

  @media ${device.laptop} {
    display: ${(props) => (props.open ? "flex" : "none")};
    width: auto;
    height: calc(100vh - 70px);
    flex-direction: column;
    justify-content: space-between;
    padding: ${(props) => props.theme.space.s6} 0;
  }
`;

const Menu = (props) => {
  const { isAuthenticated } = useAuth0();
  const [state, setState] = useState({ open: false });

  const toggleOpen = () => {
    setState((prevState) => ({ ...prevState, open: !prevState.open }));
  };

  const closeMenu = () => {
    setState({ open: false });
  };

  return (
    <MenuWrap>
      <HeadWrap>
        <TwdLogo to={"/"}>
          <img src={logo} alt="logo" />
        </TwdLogo>
        <MenuBtn open={state.open} onClick={toggleOpen}>
          <div />
          <div />
          <div />
        </MenuBtn>
      </HeadWrap>
      <MenuLinksWrap open={state.open}>
        <MenuLinksList>
          {Links.map((link) => (
            <li>
              <MenuLink
                activeClassName="is-active"
                to={link.to}
                onClick={closeMenu}
              >
                <p>
                  <b>{link.title}</b>
                </p>
              </MenuLink>
            </li>
          ))}
        </MenuLinksList>
       
        {isAuthenticated && (
          <LogoutButton />
        )}
       
        <MenuDivider />
        <MenuIconsList>
          <li>
            <a
              href="https://dribbble.com/furthertype"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={dribbble} alt="social" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/furthertypefoundry/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={insta} alt="social" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/thomas-weaver-design/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linked} alt="social" />
            </a>
          </li>    
        </MenuIconsList>
        
      </MenuLinksWrap>
    </MenuWrap>
  );
};

export default Menu;
