import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Bullets from "../layout/bullet";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import VwfsHero from "../assets/projects/vwfsHero.jpg";
import Vwfs1 from "../assets/projects/vwfs1.jpg";
import Vwfs2 from "../assets/projects/vwfs2.jpg";
import Vwfs3 from "../assets/projects/vwfs3.jpg";
import Vwfs4 from "../assets/projects/vwfs4.jpg";
import Vwfs5 from "../assets/projects/vwfs5.jpg";
import SectionContent from "../layout/sectionContent";

function VWFS() {
  return (
    <div>
      <ProjectHero
        client="Volkswagen FS"
        heroImg={VwfsHero}
        heading="Car leasing platform for Volkswagen Group"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small><b>Year:</b> 2019</small>
            </Tag>
            <Tag>
              <small><b>Type:</b> Web
            Platform</small>
            </Tag>
            <Tag>
              <small><b>Role:</b> Project Lead,
            Design Lead</small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            VW DRIVE is a vehicle leasing (contract hire) platform for VW Group
            brands. The MVP platform was released in Q1 2019 with a focus on
            leasing Volkswagen Commercial Vehicles stock to business users.
          </h5>
          <p>
            The key objective of the platform, post-MVP, was to begin
            on-boarding VW Group passenger vehicle brands, beginning with Audi.
            Picking up the responsibility of Design Lead and PM, my role was to
            start preparing the platform to accommodate passenger vehicle stock,
            and to cater for the needs of passenger vehicle customers.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Vwfs1} />
        <SectionContentBody>
          <h5>Discovery</h5>
          <p>
            As the MVP platform had been focused on business customers leasing
            commercial vehicles, we ran a discovery phase to help identify and
            understand the unique needs of passenger vehicle customers. We began
            with a kick-off workshop with the key stakeholder group to revisit
            and update the business objectives for the platform, review relevant
            customer insights, and review analytics data gathered from the live
            MVP platform.
          </p>

          <br />
          <p>Alongside this, our discovery activities included:</p>
          <Bullets>
            <li>
              <p>
                User interviews and usability testing to map the user journey
                for passenger vehicle customers, capture insights into how they
                expected to use the platform, and identify gaps.
              </p>
            </li>
            <li>
              <p>
                Stakeholder interviews to align on brand requirements for
                on-boarding passenger vehicles stock to the platform.
              </p>
            </li>
            <li>
              <p>
                Quantitative data analysis to identify opportunities to enhance
                the MVP platform.
              </p>
            </li>
            <li>
              <p>Comparative benchmarking for in and out of class services.</p>
            </li>
            <li>
              <p>Creating new personas for passenger vehicles customers.</p>
            </li>
          </Bullets>
        </SectionContentBody>
        <SectionContentImg src={Vwfs5} />
        <SectionContentBody>
          <h5>What we learned</h5>
          <p>
            Our discovery activities showed that customers wanted a way to
            quickly toggle between passenger and commercial vehicles pricing and
            information, rather than to be sent along a unique user journey for
            either, disproving our initial hypothesis that each customer group
            would require a unique user journey. Some vehicle features, such as
            load capacity, were more important to business customers, and needed
            to be prioritised hierarchically when switching between passenger
            and commercial vehicles.
          </p>

          <br />
          <p>
            Alongside this, we identified a number of UX enhancements through a
            combination of our user testing sessions and analytics data reviews,
            which included:
          </p>
          <Bullets>
            <li>
              <p>
                Fine-tuning the platform’s proposition to better leverage the
                brand equity of VW Group.
              </p>
            </li>
            <li>
              <p>
                Optimising how vehicle information was displayed through the
                end-to-end user journey, to help user’s more easily undertand a
                vehicles suitability.
              </p>
            </li>
            <li>
              <p>
                Improving filtering options through the end-to-end user journey,
                based on customer feedback, to enable customers to better
                discover vehicles that met their personal needs.
              </p>
            </li>
            <li>
              <p>
                Enabling customers to complete a pre-credit check online before
                progressing with their lease.
              </p>
            </li>
          </Bullets>
        </SectionContentBody>
        <SectionContentImg src={Vwfs2} />
        <SectionContentBody>
          <h5>Enhancing and extending the platform</h5>
          <p>
            After the post-MVP enhancements were launched, the DRIVE platform
            increased lead generation month-on-month, continuing to do so as we
            on-boarded more VWG brands. I continued to lead the DRIVE project
            team for over 3 years, using a human-centered, data-led approach to
            iteratively enhance and extend the user experience of the DRIVE
            platform, whilst maintaining our close working relationship with
            VWFS and their internal teams to ensure new business requirements
            were captured and implemented as the platform grew.
          </p>
        </SectionContentBody>
        <SectionContentImg src={VwfsHero} />
        <SectionContentBody>
          <h5>
            Working with Futurice has been a great experience, and everyone
            within VWFS and VW Group recognise the quality of work that has been
            delivered. Lastly, it’s been fun!!!
          </h5>
          <p>- John Coker, Product Owner, VWFS UK</p>
        </SectionContentBody>
        <SectionContentImg src={Vwfs4} />
        <SectionContentBody>
          <h5>Impact</h5>
          <p>
            Drive is the first ever digital service that allows customers to
            lease vehicles from multiple Volkswagen Group brands directly from
            VWFS. This gives back control of the customer experience to VWFS,
            and reduces the regulatory risk associated with selling through
            third-party leasing companies. It’s also a huge step forward from a
            technology perspective, in terms of allowing VWFS to more easily
            experiment with new propositions on the platform, and adapt to the
            rapidly changing mobility landscape.
          </p>
        </SectionContentBody>
        <SectionContentImg src={Vwfs3} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default VWFS;
