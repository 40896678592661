import styled from "styled-components";
import device from "../global/mediaQueries";

const HeroContent = styled.div`
  display: flex;
  align-items: left;
  flex-direction:column;
  max-width: 1200px;
  width:100%;

  h1 {
    font-weight: 400;
    font-size: 64px;
    line-height: 82px;
    margin: ${props => props.theme.space.s6} 0 ${props => props.theme.space.s8} 0;


    b {
      font-weight: 700;
    }

    @media ${device.laptop} {
      font-size: 44px;
      line-height: 48px;
    }
  }
  h2, h3, h4, h5, p {
    color: ${(props) => props.theme.colors.black};
    font-weight:normal;
  }

  
`;

export default HeroContent;
