import styled from "styled-components";
import device from "../global/mediaQueries";
import BG1 from "../assets/img/bg-1.jpg";

const HeroWrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.keyline};
  background-image: url(${BG1});
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: ${(props) => props.theme.space.s7} 0px;

  small {
    max-width: 1200px;
    width: 100%;
    color: ${(props) => props.theme.colors.black};
  }

  @media ${device.laptopL} {
    padding: 120px ${(props) => props.theme.space.s5}
      ${(props) => props.theme.space.s7} ${(props) => props.theme.space.s5};
  }
  @media ${device.laptop} {
    padding: 120px ${(props) => props.theme.space.s5}
      ${(props) => props.theme.space.s7} ${(props) => props.theme.space.s5};
  }
`;

export default HeroWrap;
