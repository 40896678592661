import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "styled-components";
import device from "../global/mediaQueries";

const LogoutButtonWrap = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid  ${(props) => props.theme.colors.keyline};
  border-radius: 6px;
  padding: 0 ${(props) => props.theme.space.s4};
  background: none;
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  transition: all 0.5s;

  p {
    font-size:14px;
    line-height:24px;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 0;

    @media ${device.laptop} {
      font-size:24px;
      line-height:32px;
    }
  }
  &:hover {
    border: 1px solid  ${(props) => props.theme.colors.accent};
  }
  @media ${device.laptop} {
      padding: ${(props) => props.theme.space.s2}; ${(props) => props.theme.space.s3};
  }


`;

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <LogoutButtonWrap onClick={handleLogout}>
      <p>
        <b>Logout</b>
      </p>
    </LogoutButtonWrap>
  );
};
