import React from "react";
import styled from "styled-components";
import ActionBtn from "../nav/actionBtn";
import { CaseStudies } from "../global/caseStudies";
import device from "../global/mediaQueries";
import { ProjectCardWrap, CardContentWrap } from "../cards/projectCard";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";
import Spacer from "../layout/spacer";

const SliderWrap = styled.div`
  display: flex;
  justify-content: space between;
  flex: 1 1 100%;
  gap: ${(props) => props.theme.space.s4};
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 12px;
  margin: ${(props) => props.theme.space.s8} 0;

  @media ${device.mobileL} {
    margin: ${(props) => props.theme.space.s6} 0;
  }
  &::-webkit-scrollbar {
    height: 4px;
    width: 0px;
    background: ${(props) => props.theme.colors.lightGrey};
  }
  &::-webkit-scrollbar-thumb:horizontal {
    background: ${(props) => props.theme.colors.accent};
  }
`;

const SlideCard = styled(ProjectCardWrap)`
  min-width: 360px;
  width: 360px;

  .thumbImg {
    height: 220px;
  }
`;

const ProjectSlider = () => {
  return (
    <div>
      <h2>Up next</h2>
      <SliderWrap>
        {CaseStudies.map((CaseStudy) => (
          <SlideCard to={CaseStudy.to}>
            <CardContentWrap>
              <div class="thumbImg">
                <img src={CaseStudy.img} alt={CaseStudy.client} />
              </div>
              <small>
                <span>
                  <b>Client /</b>
                </span>{" "}
                {CaseStudy.client}
              </small>
              <h4>{CaseStudy.desc}</h4>
            </CardContentWrap>
            <ActionBtn page={CaseStudy.to} />
          </SlideCard>
        ))}
      </SliderWrap>
    </div>
  );
};

export default ProjectSlider;
