import React from "react";
import styled from "styled-components";
import device from "../global/mediaQueries";
import { Link } from "react-router-dom";

export const ProjectCardWrap = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  width: calc(50% - 12px);

  &:visited, h4 {
    color: ${(props) => props.theme.colors.black};
  }

  .thumbImg {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 340px;
    background: black;
    margin-bottom: ${(props) => props.theme.space.s5};

    img {
      object-fit: cover;
      height: auto;
      max-height: 100%;
      width: auto;
      min-width: 100%;
      transition: transform 3s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
  h4 {
    margin-bottom: ${(props) => props.theme.space.s6};
  }
  small {
    display: block;
    margin: 0 0 ${(props) => props.theme.space.s4} 0;
  }

  @media ${device.laptop} {
    width: calc(50% - ${(props) => props.theme.space.s4});
  }
  @media ${device.mobileL} {
    width: 100%;
    
  }
`;

export const CardContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
