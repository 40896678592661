import styled from "styled-components";
import { Link } from "react-router-dom";
import device from "../global/mediaQueries";

const MenuLink = styled(Link)`
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  p {
    font-size:14px;
    line-height:24px;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 0;

    @media ${device.laptop} {
      font-size:24px;
      line-height:32px;
    }

  }
`;

export default MenuLink;