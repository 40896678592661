import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Bullets from "../layout/bullet";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import dsdHero from "../assets/projects/dsd-thumb3.jpg";
import dsd1 from "../assets/projects/dsd1.jpg";
import dsd2 from "../assets/projects/dsd2.jpg";
import dsd3 from "../assets/projects/dsd3.jpg";
import dsd4 from "../assets/projects/dsd4.jpg";
import dsd5 from "../assets/projects/dsd5.jpg";
import dsd6 from "../assets/projects/dsd6.jpg";
import dsd7 from "../assets/projects/dsd7.jpg";
import dsd8 from "../assets/projects/dsd8.jpg";
import SectionContent from "../layout/sectionContent";

function Dsd() {
  return (
    <div>
      <ProjectHero
        client="Various Organisations"
        heroImg={dsdHero}
        heading="Defining, building, and managing design systems"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2024
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Design Systems
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Design Director
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Tools:</b> Figma, Miro, StoryBook
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            Over the last six years at Futurice we've seen a steady increase in requests from clients to help them to define, build, scale and manage their design systems, leading to us doing exactly that for clients including Reuters, The RAC, Recycle Now, Ashurst LLC, Jagex, and many more.
          </h5>
          <p>
            As product design Practice Lead at Futurice, and a longtime proponent of design systems, I've been lucky enough to lead all of our design system engagements, rolling up my sleeves and and getting stuck in with the complicated challenge of building systems that prioritise value for the teams who’ll use them. Along the way I’ve been able to take the most effective elements from that experience and craft them into a first class toolkit of methods and best practices to help organisations to define systems that work for them.
          </p>
        </SectionContentBody>
        <SectionContentImg src={dsd1} />
        <SectionContentBody>
          <h5>Design System Definition</h5>
          <p>
            The biggest stumbling block I've observed time and time again while working with a wide range of organisations, is them trying to copy what everyone else appears to be doing instead of focusing on what's most valuable, and manageable for their specific needs. So, to help address this problem, while working across a wide range of client engagements spanning the last five years at Futurice, I began to progressively develop a framework that grew into our Design System Definition methodology.
          </p>
          <br />
          <p>
            With each of those new engagements I observed the opportunity to refine and codify the methods and activities we used to co-create design systems, into a reusable toolkit to help teams to create a solid foundation for the creation of their own design systems.
          </p>
          <br />
          <p>
            The Design System Definition (DSD) framework provides teams with a curated set of canvases that walk them through the necessary steps for successfully planning the foundations of a new, scalable design system. Rooted in our experience working with a huge number of organisations to build and scale design systems effectively, DSD provides structure for defining design systems using a comprehensive set of canvases to guide teams through the process in a manner that makes sure they're asking the right questions and solving the right problems, before jumping into design tools.
          </p>
        </SectionContentBody>
        <SectionContentImg src={dsd2} />
        <SectionContentBody>
          <h5>Building best in class components</h5>
          <p>
            The most effective way to start demonstrating the value of a new design system, is to get some reusable styles, components, and patterns built, and into use, but often, a well planned design system is only as good as the assets within it.
          </p>
          <br />
          <p>
            Leveraging the deep technical expertise of my team (we're Figma nerds), I've helped to coach our Clients on best practices for constructing their components and patterns, ensuring that their design files and systems are lean, efficient and optimised for reusability. I work with client design teams to help them identify when to use variants vs layer properties, how to wield booleans effectively, and how to document their components in the most effective way to support handover and onboarding. Alongside this I help teams  to identify what’s the absolute leanest documentation required for their designers to successfully use a component, and for their engineers to build it.
          </p>
        </SectionContentBody>
        <SectionContentImg src={dsd4} />
        <SectionContentBody>
          <h5>Putting it into practice with Reuters</h5>
          <p>
            I’ve spent the last year leading our integrated product design team to help Reuters to build and manage their first fully functioning design system, supporting the redesign and unification of their Reuters Pictures and Connect platforms. Using the DSD framework I led a team of Futurice and Reuters designers to define the foundations of a design system that was fine-tuned for Reuters’ capabilities and resourcing.
          </p>
          <br />
          <p>
            With a relatively small, inexperienced team available to manage and scale the system, and some hard deadlines to deliver the new unified Connect platform, it was imperative that we created something that was lean and laser focused on immediate value. Some of the key outputs of our work have included:
          </p>
        </SectionContentBody>
        <SectionContentImg src={dsd3} />
        <SectionContentBody>
          <h5>An Atomic Design component library</h5>
          <p>
            An effective component library is more than just some nicely organised assets. We began by exploring suitable categorisation methodologies to provide a clear approach to structuring the component library, eventually landing on the Atomic Design methodology due to its clear and intuitive approach to classifying components based on their complexity and configuration.
          </p>
          <br />
          <p>
            We opted to house all Atomic components in a single Figma file, supported by multiple style guide files to house things like Colours, Grids, and typography, as this was the most intuitive and least complex set up for the team to manage.
          </p>
          <br />
          <p>
            Finally, we worked with the Reuters team to identify the highest value documentation to include in the component library for designers and devs, crafting reusable templates to house this information, alongside guidelines on how, when and why to create it.
          </p>
        </SectionContentBody>
        <SectionContentImg src={dsd6} />
        <SectionContentBody>
          <h5>A robust set of governance processes</h5>
          <p>
            Working closely with the Reuters team, we defined a lean set of governance processes that were progressively developed over a series of workshops. These included:
          </p>
          <Bullets>
            <li>
              <p>
                <b>A contribution model</b> decision flow showing how to use, scale and modify the system from briefing to deployment of new or updated experiences.
              </p>
            </li>
            <li>
              <p>
                <b>A Definition of Done (DoD)</b> to ensure consistency of standards for components in the Design System
              </p>
            </li>
            <li>
              <p>
                <b>Merging, publishing, and versioning process,</b> detailing when and how to use Figma’s branching features to update the library, how to schedule publishes and how to categorisation versions of the design system
              </p>
            </li>
          </Bullets>
          <p>
            All of these processes were clearly documented directly within the relevant Design System project files as elements of a usage guide, and within a Design System Wiki to support onboarding of new users and contributors.
          </p>
        </SectionContentBody>
        <SectionContentImg src={dsd5} />
        <SectionContentBody>
          <h5>Clear communication processes and tracking</h5>
          <p>
            To ensure that the design system could be maintained and scaled alongside BAU design work, we established Standards for clear communication for publishing design system updates ensuring that product teams had suitable warning of updates in order to reduce impact on BAU work, and to secure time to review updates across existing UX flows.
          </p>
          <br />
          <p>
            Dedicated Teams channels were set up to communicate and clarify impending updates and facilitate questions, comments and conversations related to the design system from the wider design, tech and product teams.
          </p>
        </SectionContentBody>
        <SectionContentImg src={dsd8} />
        <SectionContentBody>
          <h5>The impact of our work</h5>
          <p>
            We didn’t build the Connect design system for Reuters, <b>we built it with them</b>. The design system we created has given Reuters a genuine single source of truth for Connect’s design assets, tailored to their unique size and capabilities as a team. They want the system to succeed because it’s their system, and they’re confident that it will succeed because they built it with us. The key impact of the Connect design system has been:
          </p>
          <Bullets>
            <li>
              <p>
                <b>Team impact:</b> A more capable team, with a common language between design and development, leading to enhanced collaboration, clearer communication, and a shared understanding of how a good component looks and functions
              </p>
            </li>
            <li>
              <p>
                <b>Business impact:</b> The Connect Design System has enabled the Reuters team to concept and prototype at a pace beyond what was previously achievable, enabling the business to  bring the new Connect platform to market at an accelerated timescale while generating limited design and tech waste or debt
              </p>
            </li>
            <li>
              <p>
                <b>Digital ecosystem impact:</b> A reusable design system framework and templates that are being used to underpin the development of other Lean design systems across the Reuters digital ecosystem, bringing efficiency and reusability to the wider organisation
              </p>
            </li>
          </Bullets>
        </SectionContentBody>

      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default Dsd;
