import styled from "styled-components";
import device from "../global/mediaQueries";

import Jagex from "../assets/icons/JagexLogo.svg";
import VWFS from "../assets/icons/vwfsLogo.svg";
import RN from "../assets/icons/rnLogo.svg";
import Arriva from "../assets/icons/arrivaLogo.svg";
import Audi from "../assets/icons/audiLogo.svg";
import BLab from "../assets/icons/blabLogo.svg";

const ClientsWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: stretch;
  width: 100%;
  gap: ${(props) => props.theme.space.s4};
  margin-top: ${(props) => props.theme.space.s8};

  @media ${device.mobileL} {
    margin-top: 0;
  }
`;

const ClientCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(16% - 12px);
  height: 140px;
  border: 1px solid ${(props) => props.theme.colors.keyline};
  border-radius: 8px;

    img {
      max-width:75%;
    } 
    
    @media ${device.laptop} {
      width: calc(33.3% - 12px);
    }

    @media ${device.mobileL} {
      width: calc(50% - 12px);
    }
  }

`;

const ClientCards = () => {
  return (
    <div>
      <ClientsWrap>
        <ClientCard>
          <img src={Jagex} alt='Jagex logo' />
        </ClientCard>
        <ClientCard>
          <img src={VWFS} alt='Jagex logo' />
        </ClientCard>
        <ClientCard>
          <img src={RN} alt='Jagex logo' />
        </ClientCard>
        <ClientCard>
          <img src={Arriva} alt='Jagex logo' />
        </ClientCard>
        <ClientCard>
          <img src={Audi} alt='Jagex logo' />
        </ClientCard>
        <ClientCard>
          <img src={BLab} alt='Jagex logo' />
        </ClientCard>
      </ClientsWrap>
    </div>
  );
};


export default ClientCards;
