import styled from "styled-components";

const SectionContent = styled.section`
  position: relative;
  display: flex;
  flex-direction:column;
  width: 100%;
  max-width:1200px;
  overflow: hidden; 

  h1 {
    margin-bottom: ${props => props.theme.space.s7};
  }

  b {
    color: ${(props) => props.theme.colors.black};
  }
 
`;
export default SectionContent;
