import styled from "styled-components";
import device from "../global/mediaQueries";

const LeadLabelWrap = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;

  h5,
  h4,
  p {
    display: block;
    margin: 0 0 0 ${props => props.theme.space.s3};
    color: ${(props) => props.theme.colors.black};
  }
  div {
    width: ${props => props.theme.space.s5};
    height: 1px;
    background: ${(props) => props.theme.colors.accent};
  }
  img {
    display: block;
    margin-left:8px;
    widht: 32px;
    height:32px;
  }
`;

const LeadLabel = ({ label, icon, alt }) => {
  return (
    <LeadLabelWrap>
      <div></div>
      <p>{label}</p>
      
        <img src={icon} alt={alt} />
      
    </LeadLabelWrap>
  );
};

export default LeadLabel;
