import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import SectionContent from "../layout/sectionContent";
import ExternalLink from "../nav/externalLink";
import Link from "../assets/icons/link.svg";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import Sub1 from "../assets/projects/sub1.png";
import Sub2 from "../assets/projects/sub2.png";
import Sub3 from "../assets/projects/sub3.png";
import Sub4 from "../assets/projects/sub4.png";
import Del1 from "../assets/projects/del1.png";
import Del2 from "../assets/projects/del2.jpeg";
import Del3 from "../assets/projects/del3.jpeg";
import Del4 from "../assets/projects/del4.jpeg";
import Val1 from "../assets/projects/val1.png";
import Val2 from "../assets/projects/val2.png";
import Val3 from "../assets/projects/val3.png";
import Val4 from "../assets/projects/val4.png";

function Furthher() {
  return (
    <div>
      <ProjectHero
        client="Furthher Type"
        heroImg={Del1}
        heading="Creating the Further Type foundry"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2022
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Typography
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Type Designer
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Tools:</b> Glyphs, Illustrator
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            Having been immersed in the world of design for over 20 years, I
            founded Further Type to share to fulfil a career-long ambition to
            create my own fully functioning, professional typefaces.
          </h5>
          <p>
            Drawing inspiration from turn of the century futurism, music, and
            architecture, founding the Further Type foundry has enabled me to
            channel my passion for clean, minimalist design aesthetics into new
            typographic works. Since starting this journey on Boxing Day 2021
            I’ve created two families, Valhalica and Delphium, spanning four
            different weights and equalling in excess of a thousand characters.
            Both Font families have been approved for sale by Monotype and are
            available to license on MyFonts through the Further type foundry.
          </p>
          <ExternalLink
            href="https://www.myfonts.com/collections/further-type-foundry?sort_by=price-ascending&pageType=foundaryPage"
            label="View on MyFonts"
            icon={Link}
            alt="Dribbble"
          />
        </SectionContentBody>
      </SectionWrap>

      <Divider />
      <SectionWrap>
        <SectionContentBody>
          <h3>
            <b>01</b>
            <span> /</span>&nbsp;Subsurface font family
          </h3>
        </SectionContentBody>
        <SectionContentImg src={Sub1} />

        <SectionContentBody>
          <h5>
            Rooted in turn-of-the-millennium futurist design, Subsurface
            maintains a contemporary sans-serif aesthetic, who's aesthetics are
            inspired by the sleek, future-facing look and feel of electronic
            music, club culture and technology.
          </h5>
          <p>
            As an elegant, bold display font, Subsurface is particularly well
            suited for headlines, logos, and other large-format uses where its
            distinctive character can capture the imagination of your audience.
          </p>
          <ExternalLink
            href="https://www.myfonts.com/collections/subsurface-font-further-type"
            label="View on MyFonts"
            icon={Link}
            alt="MyFonts"
          />
        </SectionContentBody>
        <SectionContentImg src={Sub2} />
        <SectionContentImg src={Sub3} />
        <SectionContentImg src={Sub4} />
      </SectionWrap>

      <Divider />

      <SectionWrap>
        <SectionContentBody>
          <h3>
            <b>02</b>
            <span> /</span>&nbsp;Valhalica font family
          </h3>
        </SectionContentBody>
        <SectionContentImg src={Val1} />
        <SectionContentBody>
          <h5>
            Valhalica is a highly legible display font that lends itself to big,
            bold headlines and logos with a Nordic tone.
          </h5>
          <p>
            The Valhalica typeface grew out of a project to design a modern
            mobile app for the ancient strategy board game Hnefatafl, also known
            as 'Viking Chess'. The typeface draws its inspiration from the
            ancient runic alphabets used by the Norse people, known as
            'futharks'. Modelling its letterforms on the aesthetics of these
            futhark alphabets, alongside an appreciation of clean, contemporary
            typography, Valhalica is a highly legible display font that lends
            itself to big, bold headlines and logos with a Nordic tone.
          </p>
          <ExternalLink
            href="https://www.myfonts.com/collections/valhalica-font-further-type"
            label="View on MyFonts"
            icon={Link}
            alt="Dribbble"
          />
        </SectionContentBody>
        <SectionContentImg src={Val2} />
        <SectionContentImg src={Val3} />
        <SectionContentImg src={Val4} />
      </SectionWrap>

      <Divider />

      <SectionWrap>
        <SectionContentBody>
          <h3>
            <b>03</b>
            <span> /</span>&nbsp;Delphium font family
          </h3>
        </SectionContentBody>
        <SectionContentImg src={Del1} />

        <SectionContentBody>
          <h5>
            Delphium is a bold, highly legible display font that lends itself to
            impactful contemporary visual communications.
          </h5>
          <p>
            Inspired by a 21st century that's yet to transpire, and a vision of
            the future that's been left in the past, Delphium is a continuation
            of the cutting edge design language of the 90s, most notably led by
            the typographic work of The Designers Republic.
          </p>
          <ExternalLink
            href="https://www.myfonts.com/collections/delphium-font-further-type"
            label="View on MyFonts"
            icon={Link}
            alt="Dribbble"
          />
        </SectionContentBody>
        <SectionContentImg src={Del2} />
        <SectionContentImg src={Del3} />
        <SectionContentImg src={Del4} />
      </SectionWrap>

      <Divider />

      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default Furthher;
