import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import RACHero from "../assets/projects/rac-thumb.jpg";
import RAC1 from "../assets/projects/rac1.jpg";
import RAC2 from "../assets/projects/rac2.jpg";
import RAC3 from "../assets/projects/rac3.jpg";
import RAC4 from "../assets/projects/rac4.jpg";
import SectionContent from "../layout/sectionContent";

function RAC() {
  return (
    <div>
      <ProjectHero
        client="RAC"
        heroImg={RACHero}
        heading="Bringing DesignOps to the RAC"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2023
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> DesignOps
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Design Director
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            The RAC is one of the UK’s most trusted roadside assistance brands.
            After a major investment in 2021, Futurice was brought in to
            transform the RAC's digital capabilities and customer experience.
          </h5>
          <p>
            A key focus of this 18-month engagement was growing modern Product
            Design capabilities whilst establishing DesignOps best practices to
            enable more effective product delivery.
          </p>
          <p>
            When Futurice began working with the RAC, Design was decoupled from
            Product, following a traditional centralised organisational model,
            where design capabilities were tactically assigned to product squads
            on an ad-hoc basis. The challenges of this model is that Design ends
            up being separate from Product making it difficult to align
            mindsets, processes and methodologies, while preventing designers
            from getting fully immersed into specific Products, thus limiting
            the opportunity to gain deeper understanding of the Product’s
            vision.
          </p>
        </SectionContentBody>
        <SectionContentImg src={RAC1} />
        <SectionContentBody>
          <h5>Bridging the gap between Design and Product</h5>
          <p>
            Futurice worked closely with RAC’s Product Squads to decentralise
            Product Design capabilities, embedding designers long-term within
            squads, enabling stronger designer-product owner relationships,
            breaking down silos, and nurturing a user-centred mindset within
            Product Squads. This new approach necessitated the definition of a
            new set of product-focused DesignOps standards to reduce friction
            and ensure that Designers had the clarity they needed on how to ‘do
            design’ in a Product context.{" "}
          </p>
          <p>
            We began by establishing a design process that was fit for purpose,
            replacing the existing linear, stage-gated process with a non-linear
            Design Thinking approach that better lent itself to Agile ways of
            working, enabling teams to identify the right problems to solve
            before iterating towards the most effective, validated solutions.
            Alongside this, we coached Product Leaders on best practices for
            integrating this Design process with Scrum, sharing guidance on how
            to effectively embed design as capability within Product Squads, to
            improve cross-functional ways of working, breaking down silos
            between Design and other capabilities.{" "}
          </p>
          <p>
            To support this new user-centred approach, Futurice worked with
            Product Squads to establish consistent processes and templates to
            facilitate best-in-class user testing and research practices. We
            produced robust guidance on how to sequence quantitative and
            qualitative research methods, supported by templates and
            documentation on how to effectively engage with customers, and
            ensure that insights are effectively analysed to inform product
            optimisations. Defining these standards helped to establish a
            consistent approach to research and customer engagement across
            Product Squads.
          </p>
        </SectionContentBody>
        <SectionContentImg src={RAC2} />
        <SectionContentBody>
          <h5>Creating a single source of truth</h5>
          <p>
            Another key friction point that we identified with the RAC’s
            existing approach to design, was the lack of a centralised Design
            Library, which often led to designers and engineers duplicating
            effort, significantly impacting delivery timescales, and creating
            inconsistencies across the RAC’s range of digital touch-points.
          </p>
          <p>
            Futurice’s design team worked closely with engineering teams to
            define and co-create the RAC’s first cross-platform, centralised
            Design System. Beginning with in-depth UI audits of the existing
            range of digital products, we reduced duplication of styles and
            patterns to create a set of core components and styles to be used
            consistently across the full range of RAC digital products.
            Alongside this we defined and documented a robust set of design
            principles and governance processes to ensure effective scalability
            of the Design System.
          </p>
          <h5>
            The Pattern Library is perfect for what we need, it sets a great
            foundation for us to go forward and build on. The governance and
            guidance is going to be fundamental to our team keeping things
            consistent.
          </h5>
          <p>- John Boyce - Lead Product Designer, RAC</p>
          <p>
            Replatforming the RAC’s design tools from Adobe XD to Figma, was a
            key aspect of building a simple and efficient Design System,
            enabling greater collaboration and efficiency through features like
            auto-layout, variants and branching. Figma's cloud-based real-time,
            multi-user capabilities allowed designers to work together
            seamlessly, alongside other capabilities within Product Squads,
            further bridging the gap between design and other capabilities. We
            established standards for Figma work to ensure consistent structure
            for pages, clear examples of naming conventions and examples of how
            to lay out and structure UX flows, reducing complexity and ensuring
            that anyone, designer or otherwise, is able to find their way around
            design files with minimum initial support.
          </p>
        </SectionContentBody>
        <SectionContentImg src={RAC3} />
        <SectionContentBody>
          <h5>Future-proofing DesignOps</h5>
          <p>
            Robust documentation was a must, to ensure clarity and adoption of
            these new standards and ways of working. The Futurice team
            co-created a Product Design Confluence space establishing a logical
            and scalable structure to help on-board new team members, and to act
            as the ‘go-to’ place for the RAC’s design team to seek answers. All
            of the new standards and ways of working that we established over
            our engagement with the RAC were documented in the Confluence space,
            which acted as a hub, linking out to resources and templates in
            tools such as Figma and Miro.
          </p>
          <p>
            As the RAC began to scale their internal Product Design team, this
            team wiki became an essential resource for rapidly bringing new team
            members up-to-speed with Design culture and ways of working within
            the organisation.
          </p>
        </SectionContentBody>
        <SectionContentImg src={RAC4} />
        <SectionContentBody>
          <h5>Paving the way for continued improvement</h5>
          <p>
            With these DesignOps foundations established by Futurice, the RAC is
            poised to deliver more consistent, impactful customer experiences at
            pace and scale. The new design practices have already shown results,
            As Futurice steps back, the RAC now has the capabilities, processes
            and culture for design to be a key driver of digital transformation.
            Empowering RAC designers through DesignOps methodologies paves the
            way for continued innovation and competitive advantage.
          </p>
        </SectionContentBody>
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default RAC;
