import styled from "styled-components";
import HeroContent from "../layout/heroContent";

const FootContent = styled(HeroContent)`
  h1 {
    font-weight: 400;
    letter-spacing: -0.02em;
    margin-bottom: ${(props) => props.theme.space.s7};
  }
  ul {
    li {
      margin: 0px ${(props) => props.theme.space.s2} 0 0;

      p:hover {
        &::after {
          width: 0%;
        }
      }
    }
  }
`;

export default FootContent;
