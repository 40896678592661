import styled from "styled-components";
import device from "../global/mediaQueries";

const SectionWrap = styled.section`
  position: relative;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 120px 0;
  overflow: hidden;

  @media ${device.laptopL} {
    padding: 120px ${(props) => props.theme.space.s6};
  }
  @media ${device.laptop} {
    padding: 80px ${(props) => props.theme.space.s5};
  }
`;
export default SectionWrap;
