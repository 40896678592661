import React from "react";
import styled from "styled-components";
import device from "../global/mediaQueries";
import HeroWrap from "../layout/heroWrap";
import HeroContent from "../layout/heroContent";
import LeadLabel from "../layout/leadLabel";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContent from "../layout/sectionContent";
import Divider from "../layout/divider";
import ProjectSlider from "../cards/projectSlider";
import Spacer from "../layout/spacer";
import ExpertiseNumber from "../layout/expertiseNumber";
import ExperienceWrap from "../layout/experienceWrap";
import AboutIcon from "../assets/icons/about.svg";
import Scroller from "../layout/scroller";

const ExperienceNumber = styled(ExpertiseNumber)`
  width: 180px;
  margin: 0 ${(props) => props.theme.space.s4} 0 0;

  p {
    padding-top: 0;
  }
  @media ${device.mobileL} {
    width: 140px;
  }
`;

const AboutContentImg = styled.img`
  position: relative;
  display: block;
  width: 100%;
  margin: ${(props) => props.theme.space.s7} 0
    ${(props) => props.theme.space.s7} 0;

  @media ${device.laptop} {
    margin: ${(props) => props.theme.space.s6} 0
      ${(props) => props.theme.space.s6} 0;
  }
`;

function About() {
  return (
    <div>
      <HeroWrap>
        <div></div>
        <HeroContent>
          <LeadLabel label="About me" icon={AboutIcon} />
          <h1>
            I'm a humble, hands-on <b>design leader empowering teams</b> to deliver beautiful interfaces &amp; intuitive experiences
          </h1>
        </HeroContent>
        <Scroller />
      </HeroWrap>
      <SectionWrap>
        <SectionContentBody>
          <h5>
            It's been <span>over eighteen years</span> since I began my journey
            as professional designer, and in that time I’ve gained a shed load
            of amazing experience, from leading teams, nurturing talent,
            managing projects, running workshops and wooing clients, through to
            defining, validating,
            <span>building and launching digital products &amp; services</span>.
          </h5>
          <h5>
            As <span>Product Design Director at Futurice UK</span> I lead
            end-to-end project work, from acquisition and pitching, through
            kickoff, discovery, delivery, launch, and beyond, helping our
            cross-functional teams to{" "}
            <span>work in an agile, user centred way</span>. Alongside project
            work, my focus is on growing our product design offering, by
            mentoring and developing our amazing practioners, and guiding our
            design processes.
          </h5>
        </SectionContentBody>
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContentBody>
          <h2>How I like to work</h2>
          <Spacer mb={20}>
            <h3>Complementing insights with innovation</h3>
          </Spacer>
          <Spacer mb={20}>
          <p>
            At one extreme designers can work purely on instinct, using
            assumptions to underpin their thinking, while at the other end of
            the spectrum they rely too much on the users to tell them everything
            they should do. In my humble opinion, the art of good design lies in
            between these two extremes; knowing how to balance what users say
            they want, with innovative thinking to surprise and delight them
            with things they hadn't yet realised they needed. As a Design
            Director I support cross-functional teams but helping them to
            identify when to seek answers and when to provide them.
          </p>
          </Spacer>
          <Spacer mb={20}>
            <h3>Staying Lean and prioritising value</h3>
          </Spacer>
          <Spacer mb={20}>
          <p>
            I help clients understand the benefit of working in a lean way,
            staying light on their feet by removing the bloat and in turn
            reducing the risk. This mindset can be applied to anything from the
            scope of a web app, to the complexity of a design system, empowering
            teams to bring value to their organisation and their customers with
            more speed and greater consistency.
          </p>
          </Spacer>
          <Spacer mb={20}>
            <h3>Directing, not dictating</h3>
          </Spacer>
          <Spacer mb={20}>
          <p>
            Being a good Design Director means not being a design dictator. I'm
            not here to give all the orders and take all the credit, and I
            certainly don’t have all the answers. As a wizened old designer,
            what I do have is nearly 20 years of experience delivering great
            design work, which I can use to help the people I'm responsible for
            leading, to push themselves to deliver their own best work.
          </p>
          </Spacer>
        </SectionContentBody>
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContentBody>
          <h2>Recent experience</h2>
          <ExperienceWrap>
            <div>
              <ExperienceNumber>
                <p>
                  <b>2018 - Present</b> <span>&nbsp;/</span>
                </p>
              </ExperienceNumber>
            </div>
            <div>
              <p>
                <b>Futurice UK</b> <span>&nbsp;/</span>
                <br /> Design Director
              </p>
            </div>
          </ExperienceWrap>
          <ExperienceWrap>
            <div>
              <ExperienceNumber>
                <p>
                  <b>2018 - 2018</b> <span>&nbsp;/</span>
                </p>
              </ExperienceNumber>
            </div>
            <div>
              <p>
                <b>Wunder</b> <span>&nbsp;/</span>
                <br /> UI &amp; Visual Design Lead
              </p>
            </div>
          </ExperienceWrap>
          <ExperienceWrap>
            <div>
              <ExperienceNumber>
                <p>
                  <b>2016 - 2018</b> <span>&nbsp;/</span>
                </p>
              </ExperienceNumber>
            </div>
            <div>
              <p>
                <b>D3R</b> <span>&nbsp;/</span>
                <br /> Senior UX Designer
              </p>
            </div>
          </ExperienceWrap>
          <ExperienceWrap>
            <div>
              <ExperienceNumber>
                <p>
                  <b>2014 - 2016</b> <span>&nbsp;/</span>
                </p>
              </ExperienceNumber>
            </div>
            <div>
              <p>
                <b>Maher Bird Associates</b> <span>&nbsp;/</span>
                <br /> Head of Design
              </p>
            </div>
          </ExperienceWrap>
        </SectionContentBody>
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContentBody>
          <h2>Education</h2>
          <ExperienceWrap>
            <div>
              <ExperienceNumber>
                <p>
                  <b>2022</b> <span>&nbsp;/</span>
                </p>
              </ExperienceNumber>
            </div>
            <div>
              <p>
                <b>Scrum.org</b> <span>&nbsp;/</span>
                <br /> Professional Scrum&trade; with User Experience I (PSU I)
              </p>
            </div>
          </ExperienceWrap>
          <ExperienceWrap>
            <div>
              <ExperienceNumber>
                <p>
                  <b>2003 - 2006</b> <span>&nbsp;/</span>
                </p>
              </ExperienceNumber>
            </div>
            <div>
              <p>
                <b>University of Essex</b> <span>&nbsp;/</span>
                <br /> Bachelor of Arts (BA)
              </p>
            </div>
          </ExperienceWrap>
          <ExperienceWrap>
            <div>
              <ExperienceNumber>
                <p>
                  <b>2001 - 2003</b> <span>&nbsp;/</span>
                </p>
              </ExperienceNumber>
            </div>
            <div>
              <p>
                <b>South Essex College</b> <span>&nbsp;/</span>
                <br /> Bachelor of Technology (BTech) Graphic Design
              </p>
            </div>
          </ExperienceWrap>
        </SectionContentBody>
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default About;
