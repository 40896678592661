import React from "react";
import styled from "styled-components";
import device from "../global/mediaQueries";
import MenuLinksList from "./menuLinksList";

const MenuIconsList = styled(MenuLinksList)`
  li {
    &:last-of-type {
       margin: 0;
    }
    @media ${device.laptop} {
      margin: 0px ${(props) => props.theme.space.s6} 0 0;
    }
  }
  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export default MenuIconsList;
