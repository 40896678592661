import styled from "styled-components";
import device from "../global/mediaQueries";

const IconBtnWrap = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: center;
  text-decoration: none;

  h5,
  h4,
  p {
    display: block;
    margin: 0 0 0 ${(props) => props.theme.space.s4};
    color: ${(props) => props.theme.colors.black};

  }
  div {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.accent};
    transition: all 0.5s;

    img {
      display: block;
      height: 70%;
    }
  }
  @media ${device.laptop} {
    margin-bottom: ${props => props.theme.space.s5};
  }
`;

const IconBtn = ({ icon, label, alt }) => {
  return (
    <IconBtnWrap src={icon} target="_blank">
      <div>
        <img src={icon} alt={alt} />
      </div>
      <p>{label}</p>
    </IconBtnWrap>
  );
};

export default IconBtn;
