import styled from "styled-components";
import device from "../global/mediaQueries";

const SectionContentImg = styled.img`
  position: relative;
  display: block;
  width: 100%;
  max-width:996px;
  margin: ${(props) => props.theme.space.s9};

  @media ${device.laptop} {
    margin: ${(props) => props.theme.space.s6};
  }
`;
export default SectionContentImg;
