import styled from "styled-components";

const TagWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  gap: 8px;
`;

export default TagWrap;
