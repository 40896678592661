import styled from "styled-components";
import device from "../global/mediaQueries";

const DividerSM = styled.div`
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.keyline};
    margin: ${(props) => props.theme.space.s7} 0;
`

export default DividerSM;