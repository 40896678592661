import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Bullets from "../layout/bullet";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import arrHero from "../assets/projects/arr-hero.jpg";
import arr1 from "../assets/projects/arr1.jpg";
import arr2 from "../assets/projects/arr2.jpg";
import arr3 from "../assets/projects/arr3.jpg";
import arr4 from "../assets/projects/arr4.jpg";
import arr5 from "../assets/projects/arr5.jpg";
import arr6 from "../assets/projects/arr6.jpg";
import arr7 from "../assets/projects/arr7.jpg";
import SectionContent from "../layout/sectionContent";

function Arr() {
  return (
    <div>
      <ProjectHero
        client="Arriva"
        heroImg={arrHero}
        heading="Using inclusive design to create more universal transportation solutions"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2023
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Web app
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Design Director
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Tools:</b> Figma, Miro
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            In late 2022 Arriva rail approached Futurice to explore how to make
            their London Overground services more accessible to the d/Deaf
            community. Through this initiative, we saw an opportunity to use
            inclusive design to improve the experience for all passengers.
          </h5>
          <p>
            Feedback from passengers in the d/Deaf community highlighted the
            challenges that d/Deaf people faced when travelling on Arriva’s rail
            services, and demonstrated to Arriva that a dedicated initiative was
            required to explore how rail operators could better service their
            needs. Alongside this, Arriva had identified a need to improve their
            current end-to-end help and information services, which would be
            further impacted by the imminent closure of ticket offices across
            the London Overground network impactful.
          </p>
        </SectionContentBody>
        <SectionContentImg src={arr1} />
        <SectionContentBody>
          <h5>Understanding the problem</h5>
          <p>
            To help our team understand the problem space that we working in, we
            began our discovery phase by workshopping with Arriva to understand
            the wider business imperatives and capture what Arriva understood to
            be the most valuable accessibility improvements needed to maximise
            impact.
          </p>
          <br />
          <p>
            To ensure that our work was routed in user centred design we planned
            field research to explore the end-to-end travel experiences of
            d/Deaf passengers. We started off by interviewing station staff and
            managers across the Arriva network to help us identify the most
            appropriate stations and routes to run our field tests, focusing on
            a mix of larger, busier locations alongside smaller, unmanned ones.
            Alongside this we worked with local charities and expert
            organisations in the d/Deaf community to recruit d/Deaf participants
            for our field research, and to up-skill our team on the nuances of
            interviewing d/Deaf people.
          </p>
          <br />
          <p>
            As an output of this engagement we agreed the following insights and
            deliverables with Arriva:
          </p>
          <Bullets>
            <li>
              <p>
                <b>Current customer journey:</b> What is the current end-to-end
                customer journey for customers who are Deaf or have hearing
                loss?
              </p>
            </li>
            <li>
              <p>
                <b>Future ‘ideal’ customer journey:</b> What do we need to do to
                remove their painpoints and deliver their unmet needs?
              </p>
            </li>
            <li>
              <p>
                <b>Innovation opportunities:</b> Where are the opportunities to
                use innovative thinking and technology to deliver the ideal
                customer journey? 
              </p>
            </li>
          </Bullets>
          <p>
            Working with a qualified British Sign Language (BSL) translator, we
            led our research participants through an end-to-end journey on the
            Arriva London overground network, beginning outside London Euston
            station and ending outside Willesden Junction station, recording
            their experiences as we went.
          </p>
        </SectionContentBody>
        <SectionContentImg src={arr2} />
        <SectionContentBody>
          <h5>What we learned</h5>
          <p>
            We observed how our research participants often relied on their own
            personal tech, such as a mobile phone, to bridge gaps in
            communication with station staff and help them to navigate
            throughout their journey, and would prefer to interact with
            information on their own devices as opposed to digital displays on
            platforms or services.
          </p>
          <br />
          <p>
            A key pain-point for d/Deaf passengers was that uninformative or
            inaccessible digital signs and announcements made it difficult to
            stay up to date. A common misunderstanding by those designing
            services for d/Deaf passengers is that written English is their
            first language when in actual fact it’s their second language, with
            British Sign Language being their first language. As such, long-form
            written English can be challenging for d/Deaf people to read,
            meaning that the language used on signage and displays needs to be
            written in a way that’s accessible to d/Deaf passengers.
          </p>
          <br />
          <p>
            Our field research helped us to identify four high-value
            opportunities to enhance the passenger experience:
          </p>
          <Bullets>
            <li>
              <p>
                <b>How might we</b> make key information and announcements
                accessible to people who are d/Deaf or have hearing loss at
                every stage of their journey?
              </p>
            </li>
            <li>
              <p>
                <b>How might we</b> ensure digital signs in stations are
                accessible, and aligned with d/Deaf-friendly communication?
              </p>
            </li>
            <li>
              <p>
                <b>How might we</b> support staff to provide a great experience
                if/when additional support is needed by d/Deaf people?
              </p>
            </li>
            <li>
              <p>
                <b>How might we</b> make Ticket Vending Machines more accessible
                and aligned with d/Deaf-friendly communication?
              </p>
            </li>
          </Bullets>
        </SectionContentBody>
        <SectionContentImg src={arr3} />
        <SectionContentBody>
          <h5>How we co-created solutions</h5>
          <p>
            We began exploring potential solutions for each of the opportunities
            we identified in our field research, creating illustrated
            storyboards for each outlining the various journey touch-points and
            interactions. Our cross-functional team, including service design,
            product design, and engineering capabilities worked together with
            Arriva’s internal team to identify how and where these opportunities
            could be addressed across the end-to-end passenger journey, through
            a combination of personal technology, signage and station staff
            availability. We then used the concepts that we created as stimuli
            for co-creation workshops with d/Deaf participants, which helped us
            to iterate our concepts and to assess the impact and value of each
            one with end users.
          </p>
          <br />
          <p>
            These co-creation workshops showed us that the highest impact
            concept to develop further was out ‘Help point reimagined’ concept,
            which enabled passengers to scan QR codes on platforms and trains to
            get service information updates and request support from a number of
            options, including in-person, remote staff support, or a webchat
            service, all with live BSL interoperation suport. This concept would
            empower all passengers to seek information and support from the
            comfort of their own mobile device, at any point along their
            journey, in a format that’s most comfortable for them. More complex
            problems might require in-person support form staff, whereas simple
            service updates could shared via webchat.
          </p>
        </SectionContentBody>
        <SectionContentImg src={arr4} />
        <SectionContentBody>
          <h5>Concept iteration and finalisation</h5>
          <p>
            A second round of field research enabled our team to further test,
            validate and iterate our ‘Help point reimagined’ concept with a wide
            range of participants including those who were and were not d/Deaf,
            to ensure that the solution we developed was inclusive for all.
          </p>
          <br />
          <p>
            Similar to our first round of field research, the second round took
            passengers on an end-to-end journey on Arriva’s London Overground
            services capturing feedback on a Figma prototype of the ‘Help point
            reimagined’ concept at various touch-points.
          </p>
        </SectionContentBody>
        <SectionContentImg src={arr5} />
        <SectionContentBody>
          <h5>
            Futurice helped us take what could have been an accessibility
            opportunity for a limited audience and leverage it to improve our
            overall proposition for everyone.
          </h5>
          <p>-Matt Bromley, Innovation & Business Excellence Manager</p>
        </SectionContentBody>
        <SectionContentImg src={arr6} />
        <SectionContentBody>
          <h5>The impact of our work</h5>
          <p>
            Have you ever missed a train announcement because you were wearing
            noise-cancelling headphones, or had difficulty understanding the
            conductor due to background noise? These were a few of the more
            universal use cases we were able to solve by taking an inclusive
            design approach. The resulting service was not simply an
            “accessibility bolt-on,” rather a scalable service solution. It
            communicates not just in British Sign Language and written English,
            but is scalable to include other languages in the future.
          </p>
          <br />
          <p>
            Most importantly, with this service in place, Arriva’s offering
            would not only be more usable to passengers, but their rail
            contracts would be more attractive for future tenders. This is the
            potential impact of taking a universal design approach to
            accessibility challenges. Critically, we expanded Arriva’s thinking
            beyond existing off-the-shelf solutions, and focused on creating a
            more universal help & information experience inspired by the d/Deaf
            community.
          </p>
        </SectionContentBody>
        <SectionContentImg src={arr7} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default Arr;
