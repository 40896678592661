import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";



function NotFound() {
  return (
    <div>
      <SectionWrap>
        <SectionContentBody>
        <h1></h1>
            <h1>There's nothing here...</h1>
          
        </SectionContentBody>
      </SectionWrap>
    
      <Foot />
    </div>
  );
}

export default NotFound;
