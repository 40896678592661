import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Bullets from "../layout/bullet";
import Caption from "../layout/caption";
import ExternalLink from "../nav/externalLink";
import Link from "../assets/icons/link.svg";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import EVHero from "../assets/projects/evHero.jpg";
import EV1 from "../assets/projects/ev1.jpg";
import EV2 from "../assets/projects/ev2.jpg";
import EV3 from "../assets/projects/ev3.jpg";
import EV4 from "../assets/projects/ev4.jpg";

import SectionContent from "../layout/sectionContent";

function EvMe() {
  return (
    <div>
      <ProjectHero
        client="VWFS, Audi"
        heroImg={EVHero}
        heading="Educting car buyers about EV ownership"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2019
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Mobile App
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Design Lead
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            In 2019 VWFS came to us to help them solve a problem; the adoption
            of electric vehicles was still a difficult choice for most car
            buyers, with many concerned about the range capabilities and
            lifestyle implications of switching to an EV.
          </h5>
          <p>
            The VWFS product team asked us to help them design a digital tool
            that could demonstrate the benefits of owning an electric vehicle to
            potential customers. Before we could do this, we needed to create a
            proof of concept (PoC) to demonstrate the potential of this tool to
            VWG brands, in order to secure support and funding for the full
            design and build of the tool.
          </p>
        </SectionContentBody>
        <SectionContentImg src={EV1} />
        <SectionContentBody>
          <h5>Discovery</h5>
          <p>
            Before we started exploring solutions, we wanted to ensure that we’d
            properly explored the problem space to identify and prioritise user
            needs. With this being a PoC, we needed to take a lean approach to
            our discovery activities, primarily using secondary research to
            gather insights to underpin our thinking. With this in mind we
            focused our PoC Discovery activities on:
          </p>

          <br />

          <Bullets>
            <li>
              <p>
                Stakeholder interviews throughout the organisation to fully
                understand to leverage VWFS’ internal knowledge and expertise in
                the EV space.
              </p>
            </li>
            <li>
              <p>
                Comparative benchmarking, in and out of class, to build an
                understanding of how other OEMs and organisations were solving
                similar problems digitally.
              </p>
            </li>
            <li>
              <p>
                Finding and collating insights on pain points for car buyers
                when considering switching to an EV, from a range of sources
                including white papers, studies, and discussions on car buyer
                web forums.
              </p>
            </li>
            <li>
              <p>
                Co-creation workshops with VWFS and VWG stakeholders to explore
                the user journey and functionality of potential solutions.
              </p>
            </li>
            <li>
              <p>
                Tech discovery to explore and validate what user data was
                possible to collect.
              </p>
            </li>
          </Bullets>
        </SectionContentBody>
        <SectionContentImg src={EV2} />
        <SectionContentBody>
          <h5>Problem definition</h5>
          <p>
            Our discovery activities showed us that frequency of charging and
            range capacities were the key concerns of potential EV customer’s,
            and that what users needed was a personalised summary of how EV
            ownership could fit their lifestyle. To meet these needs we designed
            a mobile app that could track user’s driving habits over a period of
            weeks, and use the data collected to show tailored information on
            how often a user would need to charge an EV, based on the personal
            driving habits.
          </p>
          <p>
            Following our research activities we learned that Audi were the OEM
            who were most keen to sponsor the EV&amp;me app, so we designed our
            PoC app to align to Audi’s branding, utilising their own existing Ui
            libraries. Through a number of workshops, the Audi UK team helped us
            to refine our PoC designs to ensure that they aligned to business
            objectives for Audi’s first EV, the e-tron 50.
          </p>
        </SectionContentBody>
        <SectionContentImg src={EV3} />
        <SectionContentBody>
          <h5>The big pitch</h5>
          <p>
            Before securing the ongoing development of the EV&amp;me app,
            Futurice were invited alongside other delivery partners to create a
            proposal for delivery, and pitch this to key stakeholders from the
            VWFS and the Audi UK team.
          </p>
          <p>
            We created an interactive pitch utilising a combination of a
            presentation slide deck supported by interactive workshop activities
            to demonstrate our culture, ways of working, and unique offering as
            a delivery partner for this engagement. Needless to say, Futurice
            won the EV&amp;me work, and the rest is history…
          </p>
        </SectionContentBody>
        <SectionContentImg src={EV4} />
        <SectionContentBody>
          <h5>What happened next</h5>
          <p>
            After the PoC phase of the project, I took on project lead duties on
            other client engagements, leaving my very talented colleagues to
            design and build the finished EV&amp;me app that’s now available on
            app stores. While I was disappointed not to pick up the post PoC
            work, I’m proud of my contribution to the PoC phase, and winning the
            project, and alongside this, I’m very proud of the amazing work my
            team mates did to get the finished product into app stores.
          </p>
          <ExternalLink
            href="https://apps.apple.com/gb/app/ev-me-by-audi/id1499102563"
            label="View on the App Store"
            icon={Link}
            alt="Dribbble"
          />
        </SectionContentBody>
        <SectionContentImg src={EV1} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default EvMe;
