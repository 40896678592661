import styled from "styled-components";

const ScrollerWrap = styled.div`
  position: relative;
  width: 24px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid ${(props) => props.theme.colors.accent};

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 6px;
    left: calc(50% - 3px);
    opacity:1;
    background: ${(props) => props.theme.colors.black};
    animation: dot 1.5s infinite;
    animation-delay: 2s;

    @keyframes dot {
        from {
            transform: translateY(0);
            opacity:1;
        }
        to {
            transform: translateY(15px);
            opacity:0;
        }
  }
`;

const Scroller = () => {
  return <ScrollerWrap></ScrollerWrap>;
};

export default Scroller;
