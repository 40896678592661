import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Bullets from "../layout/bullet";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import DOHero from "../assets/projects/do-thumb.jpg";
import DO1 from "../assets/projects/do1.jpg";
import DO2 from "../assets/projects/do2.jpg";
import SectionContent from "../layout/sectionContent";

function DesignOps() {
  return (
    <div>
      <ProjectHero
        client="Futurice"
        heroImg={DOHero}
        heading="DesignOps - Small team starter guide"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2023
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Thought Leadership
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Writer, Thinker, Designer
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            <a href="https://futurice.com/blog/designops-small-team-starter-guide">
              DesignOps - Small team starter guide
            </a>{" "}
            is a thought leadership piece that grew out of my experiences
            working with a wide range of small to medium size design teams, over
            the last few years, and noticing some of the common challenges they
            faced trying to build and scale effective design standards.
          </h5>
          <p>
            The conversation about DesignOps often seems to focus on large,
            enterprise-level organisations. I think this creates a false
            impression that DesignOps is less of a concern for small teams.
            This, of course, is rubbish. In our experience, the core elements of
            DesignOps are beneficial to teams and organisations of any size, and
            are fundamental to growing and nurturing an effective design
            practice within any organisation
          </p>
        </SectionContentBody>
        <SectionContentImg src={DO1} />
        <SectionContentBody>
          <h5>“Tell me what it is before I decide to read it...”</h5>
          <p>
            If you ever find yourself saying “Oh sh*t, now our team’s so big
            we’d better start thinking about DesignOps…” you’re likely going to
            find yourself at the start of an uphill struggle to retroactively
            define and embed standards and processes that could and should have
            been defined some time ago.
          </p>
          <p>
            The ‘DesignOps - Small team starter guide’ article explores five key
            areas that I recommend small teams focus on in order to build solid
            foundations and start seeing the benefits of DesignOps.
          </p>
          <Bullets>
            <li>
              <p>Clarify your organisational model</p>
            </li>
            <li>
              <p>Define a design process that’s fit for purpose</p>
            </li>
            <li>
              <p>Standardise your tooling</p>
            </li>
            <li>
              <p>Build your sources of truth</p>
            </li>
            <li>
              <p>Nurture transparency</p>
            </li>
          </Bullets>
          <p>
            You can read the full ‘DesignOps - Small team starter guide’
            article, for free{" "}
            <span>
              <b>
                <a href="https://futurice.com/blog/designops-small-team-starter-guide">
                  here
                </a>
              </b>
            </span>
          </p>
        </SectionContentBody>
        <SectionContentImg src={DO2} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default DesignOps;
