import styled from "styled-components";
import device from "../global/mediaQueries";

const ExpertiseNumber = styled.div`
    flex-grow: 0;
    width: ${(props) => props.theme.space.s7};
    margin: ${(props) => props.theme.space.s1} ${(props) => props.theme.space.s5} 0 0;

    p {
        padding-top: ${(props) => props.theme.space.s1};
        color: ${(props) => props.theme.colors.black};
    }
    @media ${device.laptop} {
        margin: ${(props) => props.theme.space.s1} ${(props) => props.theme.space.s2} 0 0;
    }
}
`;
export default ExpertiseNumber;
