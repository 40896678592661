import styled from "styled-components";
import { Link } from "react-router-dom";
import device from "../global/mediaQueries";
import ArrowRight from "../assets/icons/arrowRight.svg";


const ActionBtnWrap = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  margin-top: ${(props) => props.theme.space.s4};

  h5,
  h4,
  p {
    display: block;
    margin: 0 ${(props) => props.theme.space.s5} 0 0;
    color: ${(props) => props.theme.colors.black};
  }
  div {
    display: flex;
    width: 88px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.keyline};
    border-radius: 8px;
    transition: all 0.5s;

    img {
      width:${(props) => props.theme.space.s5};
    }

    &:hover img {
      animation: moveRight 2s infinite;
    }
    @keyframes moveRight {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateX(0);
      }
      40% {
        transform: translateX(-15px);
      }
      60% {
        transform: translateX(-5px);
      }
  }
  @media ${device.laptop} {
  }
`;

const ActionBtn = ({ label, page }) => {
  return (
    <ActionBtnWrap to={page}>
      <p>{label}</p>
      <div>
        <img src={ArrowRight} alt="Right arrow"></img>
      </div>
    </ActionBtnWrap>
  );
};

export default ActionBtn;
