import styled from "styled-components";

const Tag = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  padding: ${(props) => props.theme.space.s2} ${(props) => props.theme.space.s4};
  border: 1px solid ${(props) => props.theme.colors.keyline};
  border-radius: 100px;

  small {
    flex-grow: 0;
    margin: 0;
  }
`;

export default Tag;
