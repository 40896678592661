import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import TCOHero from "../assets/projects/tco-thumb.jpg";
import TCO1 from "../assets/projects/tco1.jpg";
import TCO2 from "../assets/projects/tco2.jpg";
import TCO3 from "../assets/projects/tco3.jpg";
import TCO4 from "../assets/projects/tco4.jpg";
import TCO5 from "../assets/projects/tco5.jpg";
import SectionContent from "../layout/sectionContent";

function TCO() {
  return (
    <div>
      <ProjectHero
        client="Volkswagen FS"
        heroImg={TCOHero}
        heading="Total cost of ownership tool for EVs"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2022
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Web app
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Design Director
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            In late 2022 VWFS identified a significant opportunity to address
            some of this confusion in the Electric Vehicle (EV) market, and to
            position the VWFS and VW brands as trusted advisors to customers who
            are considering making the switch to an EV.
          </h5>
          <p>
            To leverage this opportunity, VWFS asked Futurice to create a
            robustly engineered Total Cost of Ownership (TCO) tool, including
            the discovery, design and build phases of the work. The aspiration
            for this new TCO tool was to show car buyers how over the full term
            of a lease, the higher monthly cost of leasing an EV compared to
            other fuel types, is offset by the cheaper running costs, making EVs
            a more affordable choice than customers realise. From a business
            perspective, this tool could give potential customers the confidence
            they need to consider transitioning to EV ownership, encouraging
            them to explore suitable options across the VWG brands.
          </p>
        </SectionContentBody>
        <SectionContentImg src={TCO1} />
        <SectionContentBody>
          <h5>Understanding the problem</h5>
          <p>
            We kicked off our work with a Discovery phase to explore the problem
            space and build our understanding of the user needs that this new
            tool TCO should answer. Our discovery work began with immersion into
            VWFS’ existing customer insights, reviewing a number of research
            reports created by the VWFS Customer Insights team to identify who
            potential EV customers are, alongside their key concerns for
            switching to EV ownership. Alongside this we undertook comprehensive
            comparative analysis of in and out of category services to build our
            understanding of what already exists in this space, and where there
            were opportunities to innovate.
          </p>
          <p>
            This initial investigation work gave us enough insight to create
            low-fidelity concepts exploring high-level UX for the TCO tool.
            These were then taken into user interviews to stimulate
            conversations with cars buyers, and build a deeper understanding of
            how to create a TCO tool that would return real value to potential
            customers.
          </p>
        </SectionContentBody>
        <SectionContentImg src={TCO2} />
        <SectionContentBody>
          <h5>What we learned</h5>
          <p>
            A key take away from the user interviews was that Total Cost of
            Ownership was a new concept to customers, and as such, very few
            people were thinking in those terms at present. Users weren’t sure
            what would be included in the TCO beyond deposit, monthly payments
            and servicing, but were excited when presented with the potential to
            include things like insurance, tax, fuel and electricity costs to
            give a comprehensive view of vehicle ownership.
          </p>
          <p>
            A tool that quickly helps users to create a personalised view of
            vehicle TCO across different fuel types, and based on their own
            budget and outgoings, could help nudge those who are on the fence,
            or who think an EV is out of range financially to take the next
            step.
          </p>
          <p>
            Some users were happy to make the effort to get as accurate an idea
            as possible of TCO, and were willing to share any personal info
            required to do so, while others simply wanted something that was
            quick and easy to get to results. For both types of user, the
            ability to edit their personal info and preferences to see updated
            results was key to appeal and usefulness of the tool.
          </p>
        </SectionContentBody>
        <SectionContentImg src={TCO3} />
        <SectionContentBody>
          <h5>How we defined the solution</h5>
          <p>
            The Tool we defined takes users through a simple, fast, and optional
            questionnaire before sharing tailored vehicle recommendations across
            fuel types, and from across the Volkswagen group. By including
            vehicles with different fuel types in the results, the tool helps
            users to learn the reality of vehicle TCO before choosing a new
            vehicle that genuinely fits their lifestyle. Alongside this, by not
            focusing solely on EVs, the tool doesn't create a dead end in the
            sales funnel for those who aren't ready to own an EV. Alongside the
            tailored TCO comparison info, the tool also includes educational
            content to raise awareness of other EV ownership benefits that could
            impact TCO, such as cheaper parking, ULEZ costs and cheaper
            insurance, building out an even broader picture of EV TCO.
          </p>
          <p>
            To ensure that internal teams could eventually take ownership of the
            TCO Tool, we worked closely with VWGs Digital Unit in Berlin to
            align our high fidelity prototyping and build work, with the
            established ways of working across the organisation. For design and
            FED this meant utilising VWGs multi-brand ‘Bronson’ design system
            using Sketch and Abstract to build our the designs. This approach
            meant that the TCO tool could take advantage of Bronson’s white
            label functionality, having its branding easily switched across VWG
            brands, so that the tool could be adapted to integrate into sales
            funnels across the groups vehicle brands.
          </p>
        </SectionContentBody>
        <SectionContentImg src={TCO4} />
        <SectionContentBody>
          <h5>The impact of our work</h5>
          <p>
            The TCO tool, now re-named to more user-friendly ‘Can an Electric
            Car fit my budget?’, went live in Q4 2023, creating a new entry
            point for potential customers into VWGs sales funnel. From a
            business perspective, our work helped to prove the effectiveness of
            Bronson’s White label functionality, to enable external partners to
            build tools that could be seamlessly and cost-effectively rolled out
            across VWG's brands.
          </p>
          <p>
            From a customer perspective, we created a simple, low-effort tool to
            help people interested in making the leap to an EV to quickly assess
            whether EV ownership was right for their lifestyle, by creating
            clarity around the true cost of vehicle ownership across different
            fuel types.
          </p>
        </SectionContentBody>
        <SectionContentImg src={TCO5} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default TCO;
