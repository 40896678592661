import { createGlobalStyle } from "styled-components";
import device from "./mediaQueries";


const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display:block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    * {
        box-sizing: border-box;
    }

    
    html, body {
        margin: 0;
        padding:0;
        font-family: 'Rubik', sans-serif;
        text-align:left;
        height: 100%;
        color: ${(props) => props.theme.colors.black};

        
    } 
    h1, h2, h3, h4, p {
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 500;
    }
    h1 {
        font-size: 64px;
        line-height: 72px;
        letter-spacing: -0.02em; 

        @media ${device.laptop} {
            font-size: 40px;
            line-height:48px;
        }
    }
    h2 {
        font-size: 44px;
        line-height: 54px;

        @media ${device.laptop} {
            font-size: 32px;
            line-height: 44px;
        }
    }
    h3 {
        font-size: 28px;
        line-height: 40px;  
        font-weight: 400;
        
        @media ${device.laptop} {
            font-size: 28px;
            line-height: 40px;
        }
        b {
            font-weight: 500;
        }
    }
    h4 {
        font-size: 24px;
        line-height: 32px;

        @media ${device.laptop} {
            font-size: 20px;
            line-height: 30px;  
        }
    }
    h5 {
        font-size: 24px;
        line-height: 32px;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        
        margin-bottom: ${props => props.theme.space.s5};

        b {
            font-weight: 500; 
        }

        @media ${device.laptop} {
            font-size: 16px;
            line-height: 24px;
        }
    }
    small {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        color: ${(props) => props.theme.colors.accent};

        b {
            font-weight: 500; 
        }
    }
    span {
        color: ${(props) => props.theme.colors.accent};
    }
    a {
        color: inherit;
    }

`;

export default GlobalStyle;
