import React from "react";
import ReactDOM from "react-dom";
import GlobalStyle from "./global/globalStyles";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ScrollToTop from "./global/scrollToTop";
import Theme from "./global/theme";
import Menu from "./nav/menu";
import reportWebVitals from "./reportWebVitals";
import Auth0ProviderWithHistory from "./global/auth0ProviderWithHistory";

import App from "./App";
import About from "./pages/about";
import Contact from "./pages/contact";
import VWFS from "./pages/vwfs";
import Devyce from "./pages/devyce";
import Vaughan from "./pages/vaughan";
import RecNow from "./pages/recNow";
import Further from "./pages/further";
import EvMe from "./pages/ev";
import PLPB from "./pages/plpb";
import DesignOps from "./pages/designOps";
import RAC from "./pages/rac";
import TCO from "./pages/tco";
import MyRAC from "./pages/myrac";
import JPP from "./pages/jpp";
import Arr from "./pages/arr";
import Dsd from "./pages/dsd";
import NotFound from "./pages/notFound";
import Callback from "./pages/callback";

// Updated ProtectedRoute component with Auth0
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  
  return (
    <Route
      {...rest}
      render={props => {
        // Show loading state while Auth0 is initializing
        if (isLoading) {
          return <div>Loading...</div>;
        }

        // If authenticated, render component
        if (isAuthenticated) {
          return <Component {...props} />;
        }

        // If not authenticated, redirect to login
        loginWithRedirect({
          appState: { returnTo: window.location.pathname }
        });
        return null;
      }}
    />
  );
};

const Routing = () => {
  return (
    <Theme>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <ScrollToTop>
            <GlobalStyle />
            <Menu />

            <Switch>
              <Route exact path="/" component={App} />
              <Route path="/pages/about" component={About} />
              <Route path="/pages/contact" component={Contact} />

              <Route path="/pages/vwfs" component={VWFS} />
              <Route path="/pages/devyce" component={Devyce} />
              <Route path="/pages/vaughan" component={Vaughan} />
              <ProtectedRoute path="/pages/recNow" component={RecNow} />
              <Route path="/pages/further" component={Further} />
              <ProtectedRoute path="/pages/ev" component={EvMe} />
              <Route path="/pages/plpb" component={PLPB} />
              <Route path="/pages/designOps" component={DesignOps} />
              <ProtectedRoute path="/pages/rac" component={RAC} />
              <ProtectedRoute path="/pages/tco" component={TCO} />
              <ProtectedRoute path="/pages/myrac" component={MyRAC} />
              <ProtectedRoute path="/pages/jpp" component={JPP} />
              <ProtectedRoute path="/pages/arr" component={Arr} />
              <ProtectedRoute path="/pages/dsd" component={Dsd} />

              <Route path="/pages/callback" component={Callback} />
              <Route component={NotFound} />
            </Switch>
          </ScrollToTop>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Theme>
  );
};

// Create root using createRoot API since you're using React 18
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Routing />);

reportWebVitals();