import styled from "styled-components";
import Info from "../assets/icons/info.svg";

const CaptionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  margin: 0 0 ${(props) => props.theme.space.s4} 0;
  width:100%;

  p {
    display: block;
    margin: 0 ${(props) => props.theme.space.s3} 0 0;
  }
  div {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
  
    img {
      width:${(props) => props.theme.space.s5};
    }
`;

const Caption = ({ label }) => {
  return (
    <CaptionWrap>
      <div>
        <img src={Info} alt="Info icon"></img>
      </div>
      <p>{label}</p>
    </CaptionWrap>
  );
};

export default Caption;
