import React from "react";
import Foot from "../layout/footer";
import SectionWrap from "../layout/sectionWrap";
import SectionContentBody from "../layout/sectionContentBody";
import SectionContentImg from "../layout/sectionContentImg";
import ProjectHero from "../layout/projectHero";
import Divider from "../layout/divider";
import DividerSM from "../layout/dividerSM";
import ProjectSlider from "../cards/projectSlider";
import Bullets from "../layout/bullet";
import Tag from "../layout/tag";
import TagWrap from "../layout/tagWrap";

import RNHero from "../assets/projects/rn-thumb.jpg";
import RN1 from "../assets/projects/rn1.jpg";
import RN2 from "../assets/projects/rn2.jpg";
import RN3 from "../assets/projects/rn3.jpg";
import RN5 from "../assets/projects/rn5.jpg";
import SectionContent from "../layout/sectionContent";

function RecNow() {
  return (
    <div>
      <ProjectHero
        client="WRAP"
        heroImg={RNHero}
        heading="Improving recycling habits in the UK"
      />
      <SectionWrap>
        <SectionContentBody>
          <TagWrap>
            <Tag>
              <small>
                <b>Year:</b> 2022
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Type:</b> Web Platform
              </small>
            </Tag>
            <Tag>
              <small>
                <b>Role:</b> Project Lead, Design Lead
              </small>
            </Tag>
          </TagWrap>
          <DividerSM></DividerSM>
          <h5>
            WRAP came to us wanting to update their dated Recycle Now website,
            having seen a huge increase in traffic during the first Covid
            lockdown.
          </h5>
          <p>
            Their ambition was maintain this high citizen engagement by
            improving the way they communicate correct recycling behaviours and
            driving citizen engagement in the educational recycling content
            hosted on the Recycle Now website.
          </p>
        </SectionContentBody>
        <SectionContentImg src={RN1} />
        <SectionContentBody>
          <h5>Discovery</h5>
          <p>
            We kicked off our work with WRAP with a dedicated discovery phase to
            explore the problem space and understand how this redesign could
            really help to offer more value to users. We started by immersing
            ourselves in the current end-to-end user journey, and carrying out a
            detailed heuristics and UX audit of the current Recycle Now website
            helped us to identify gaps in the experience and opportunities to
            improve the service. Alongside this, our discovery activities
            included:
          </p>

          <br />
          <p>Alongside this, our discovery activities included:</p>
          <Bullets>
            <li>
              <p>
                Stakeholder interviews throughout the organisation to fully
                understand WRAP’s ambitions and to leverage the knowledge within
                their internal teams.
              </p>
            </li>
            <li>
              <p>
                Quantitative data analysis to identify and understand patterns
                from the Recycle Now website’s analytics.
              </p>
            </li>
            <li>
              <p>
                Working closely with the WRAP marketing team to consolidate
                their visual identity into a consistent, coherent and
                contemporary digital aesthetic, without compromising high
                accessibility standards.
              </p>
            </li>
            <li>
              <p>
                Co-creation workshops to explore the user journey and redefine
                the content taxonomy of the website
              </p>
            </li>
          </Bullets>
        </SectionContentBody>
        <SectionContentImg src={RN2} />
        <SectionContentBody>
          <h5>Problem definition</h5>
          <p>
            Problem definition Through our discovery activities we learned that
            the key problem to solve for users of the Recycle Now website was to
            help them reduce contamination by improving the ease of recycling,
            by offering users tailored information based on their location,
            presented in a clear and concise way.
          </p>
          <p>
            We initial co-created low-fi UX wireframes, which were iterated
            towards high-fidelity prototypes, through multiple rounds of user
            testing. The service that Recycle Now provides is intended for
            literally everyone in the UK to use, so we needed to ensure that we
            tested and validated our solution concepts with an appropriate range
            of citizens. Using WRAP's citizen segmentation insights, we
            identified the types of people who would benefit the most from
            improving their recycling behaviours, and created an online survey
            to recruit people for user interviews and testing.
          </p>
          <p>
            Multiple rounds of user interviews and usability testing were then
            carried out to ensure that our concept was helping people to solve
            the right problems in the most accessible way.
          </p>
        </SectionContentBody>
        <SectionContentImg src={RN3} />
        <SectionContentBody>
          <h5>What we built</h5>
          <p>
            Our solution consolidated multiple existing data sources to make it
            quicker and more intuitive for people to find out if an item can be
            recycled and if so, where and how. we were able to combine WRAP’s
            existing recycle location and nationwide recycling containers APIs
            to offer users tailored information based on their location,
            presented in a clear and concise way.
          </p>
        </SectionContentBody>
        <SectionContentImg src={RN5} />
        <SectionContentBody>
          <h5>Why our work mattered</h5>
          <p>
            The new Recycle Now website helps to reduce contamination by
            improving the ease of recycling, by offering users tailored
            information based on their location, presented in a clear and
            concise way. We captured user insights and built a solution for
            everybody, modelling accessibility best practices. As mentioned
            earlier, the Recycle Now website is for everyone in the UK, so we
            designed it to achieve a minimum AA WCAG accessibility rating, with
            a AAA rating for the visual design. Our designs accommodated a dual
            language approach, meaning the site would work in English and as a
            sister site in Welsh.
          </p>
        </SectionContentBody>
        <SectionContentImg src={RNHero} />
      </SectionWrap>
      <Divider />
      <SectionWrap>
        <SectionContent>
          <ProjectSlider />
        </SectionContent>
      </SectionWrap>
      <Foot />
    </div>
  );
}

export default RecNow;
