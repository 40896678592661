import React from "react";
import styled from "styled-components";
import device from "../global/mediaQueries";

const MenuLinksList = styled.ul`
  width: auto;
  display: flex;
  align-items: left;

  li {
    position: relative;
    width: auto;
    height: auto;
    margin: 0px ${props => props.theme.space.s6} 0 0;
    padding-bottom: 0px;

    

    @media ${device.laptop} {
      margin: ${props => props.theme.space.s5} 0;
      padding-bottom: ${props => props.theme.space.s3};

      &:last-of-type {
        margin: ${props => props.theme.space.s5} 0;
        padding-bottom: ${props => props.theme.space.s3};    }
      }

    p::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0px;
      width: 0px;
      height: 1px;
      background: ${(props) => props.theme.colors.accent};
      transition: all 0.5s;
    }
    p:hover {
      &::after {
        width: 100%;
      }
    }
    img {
      width: ${props => props.theme.space.s5};
      height: ${props => props.theme.space.s5};
      transition: all 0.5s;

      &:hover {
        transform: scale(1.2);
      }
    }
    
  }
  @media ${device.laptop} {
    display:flex;
    flex-direction:column;
  }
`;

export default MenuLinksList;