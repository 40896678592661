import styled from "styled-components";

const Bullets = styled.ul`
  list-style: none;

  li::before {
    content: "•";
    font-size:40px;
    line-height:28px;
    color: ${(props) => props.theme.colors.accent};
    margin-right: ${(props) => props.theme.space.s4};
  }
  li {
    display: flex;
  }
`;

export default Bullets;
